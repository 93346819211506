'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import indexBy from 'lodash.indexby';
import platform from 'platform';
import FeedGoals from './Goals.react';
import SpringFeed from './SpringFeed.react';
import AddMissingMeal from './Feed/AddMissingMeal.react';
import MealCard from './Feed/MealCard.react';
import FeedCoachmark from './Feed/Coachmark.react';
import ChangePasswordModal from './Feed/ChangePasswordModal.react';
import MealStore from '../../stores/MealStore';
import MealActions from '../../actions/MealActions';
import { mealSortCompare } from '../../utils/Meals';
import {
    getNutrientsForMeals,
    computeDefaultMealRx,
    getNeededNutrients,
    compareNutrientsToEnvelope,
} from '../../utils/Nutrition';
import { getPrimaryMeal, getTagsForMeals, getIngredientTagsForMeals } from '../../utils/Meals';

import './Feed.scss';

const properMealOrder = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];
const FEED_SIZE_PAST = 7;
const FEED_SIZE_FUTURE = 7;

export default class Feed extends Component {
    static propTypes = {
        defaultMealType: PropTypes.string,
    };

    static defaultProps = {
        deafultMealType: 'Dinner',
    };

    static contextTypes = {
        user: PropTypes.object,
        preferences: PropTypes.object,

        meals: PropTypes.array,
        plans: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,
        brands: PropTypes.object,
        groceries: PropTypes.array,
        synced: PropTypes.bool,
        loaded: PropTypes.bool,

        showMealDetails: PropTypes.func,
        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        startRescheduleMeal: PropTypes.func,
        onRemoveMeals: PropTypes.func,

        syncMealsToGroceries: PropTypes.func,
        removeMealsFromGroceries: PropTypes.func,
        autopopulate: PropTypes.func,
        populating: PropTypes.bool,

        router: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        confirm: PropTypes.func,

        viewportWidth: PropTypes.number,
        isCordova: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const { location, preferences } = this.context;

        const defaultActiveDate =
                preferences.start_date && moment().isSameOrBefore(preferences.start_date, 'day')
                    ? moment(preferences.start_date)
                    : moment(),
            activeDate = location.query.date ? moment(location.query.date) : defaultActiveDate,
            activeMeal = activeDate.isSame(moment()) ? props.defaultMealType : 'Breakfast';

        const startDate = moment(activeDate).subtract(FEED_SIZE_PAST, 'days'),
            endDate = moment(activeDate).add(FEED_SIZE_FUTURE, 'days');

        const items = this.getItems(
            context,
            startDate,
            endDate,
            activeDate,
            activeMeal,
            props.defaultMealType,
            this.props.defaultMealType
        );

        const activeItem = items.find(
            (item) => moment(item.date).isSame(activeDate, 'day') && item.mealType === activeMeal
        );

        this.state = {
            items,

            startDate,
            endDate,
            activeDate,
            activeMeal,
            activeItem,

            thumped: null,
            activeFlipped: false,

            showSplash: false,
            isCoachmarkVisible: false,
        };

        this.handleResize = debounce(this.handleResize, 100);
        this.resetThumped = debounce(this.resetThumped, 50);
    }

    componentDidMount = () => {
        if (process.browser) {
            window.addEventListener('resize', this.handleResize);
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { activeDate, activeMeal, startDate, endDate } = this.state;
        const { user, meals } = this.context;

        const items = this.getItems(nextContext, startDate, endDate, activeDate, activeMeal, nextProps.defaultMealType);
        const activeItem = items.find(
            (item) => moment(item.date).isSame(activeDate, 'day') && item.mealType === activeMeal
        );

        this.setState({ items, activeItem });
    };

    componentWillUnmount = () => {
        if (process.browser) {
            window.removeEventListener('resize', this.handleResize);
        }
    };

    shouldShowCoachmark = () => {
        const { location } = this.context;

        if (location && location.query && location.query.signup) {
            return true;
        }

        return false;
    };

    focusFirstMeal = true;

    beginAutopopulate = async (startDate) => {
        const { preferences } = this.context;
        const { items } = this.state;

        // Record each item that we're auto-populating for so the front-end knows how to handle it differently.
        const endDate = moment(startDate).add(preferences.shopping_freq, 'day');
        items.forEach((item) => {
            if (startDate.isSameOrBefore(item.date, 'day') && endDate.isSameOrAfter(item.date, 'day')) {
                item.populating = true;
            }
        });

        this.setState({ items });
    };

    completeAutopopulate = async (response, replace = false, splash = false) => {
        const { defaultMealType } = this.props;
        const { items } = this.state;

        items.forEach((item) => delete item.populating);
        const newState = { items, isCoachmarkVisible: this.shouldShowCoachmark() };

        if (response) {
            const { meals } = response;

            // Find the first meal generated with the auto-populator
            const firstMeal = meals.sort(mealSortCompare)[0];

            // then scroll to it sometimes
            if (this.focusFirstMeal && firstMeal && splash) {
                newState.activeMeal = firstMeal.meal;
                newState.activeDate = moment(firstMeal.date);

                this.focusFirstMeal = false;
            }
        }

        this.setState(newState, () => {
            if (!replace) {
                this.centerScrollable();
            }
        });
    };

    resetThumped = () => {
        this.setState({ thumped: null });
    };

    centerScrollable = () => {
        let { items, activeItem, activeMeal, activeDate } = this.state;
        const { meals, user } = this.context;
        const { defaultMealType } = this.props;

        if (!activeItem) {
            return;
        }

        const activeIndex = items.indexOf(activeItem);

        // Don't re-center if we don't need to.
        if (activeIndex > 6 && items.length - activeIndex > 6) {
            return;
        }

        let startDate = moment(activeDate).subtract(FEED_SIZE_PAST, 'day');

        // We only need to re-center when we've changed startDates
        if (this.state.startDate.isSame(startDate, 'day') && activeItem.mealType == activeMeal) {
            // activeItem = this.state.items.find(item => item.date.isSame(activeDate, 'day') && item.mealType === activeMeal);
            // this.setState({activeItem});
            return;
        }

        let endDate = moment(activeDate).add(FEED_SIZE_FUTURE, 'day');
        items = this.getItems(this.context, startDate, endDate, activeDate, activeMeal, defaultMealType);
        activeItem = items.find((item) => item.date.isSame(activeDate, 'day') && item.mealType === activeMeal);

        this.setState({ startDate, endDate, items, activeItem }, () => {
            MealActions.ensureDateRangeLoaded(startDate, endDate);
        });
    };

    handleResize = () => {
        const { activeMeal, activeDate } = this.state;

        this.scrollToMeal(activeDate, activeMeal, false);
    };

    setActiveMeal = (activeDate, activeMeal) => {
        let { items } = this.state;
        const { meals } = this.context;

        let activeItem = items.find(
            (item) => moment(item.date).isSame(activeDate, 'day') && item.mealType === activeMeal
        );

        // are we on the same day as the prev? Don't update anything other than the indexes
        if (this.state.activeDate.isSame(activeDate, 'day') && activeItem) {
            if (this.state.activeMeal === activeMeal) {
                return; // no-op, we're already here
            }

            return this.setState({ activeDate, activeMeal, activeItem }, () => {
                this.scrollToMeal(activeDate, activeMeal);
            });
        }

        let startDate = moment(activeDate).subtract(FEED_SIZE_PAST, 'day');
        let endDate = moment(activeDate).add(FEED_SIZE_FUTURE, 'day');
        items = this.getItems(this.context, startDate, endDate, activeDate, activeMeal, this.props.defaultMealType);
        activeItem = items.find((item) => moment(item.date).isSame(activeDate, 'day') && item.mealType === activeMeal);

        this.setState({ activeDate, activeMeal, activeItem, startDate, items }, () => {
            this.scrollToMeal(activeDate, activeMeal, false);
        });
    };

    animating = false;

    scrollToMeal = (activeDate, activeMeal, animate = true, callback = null) => {
        if (!process.browser) {
            return;
        }

        // Don't try scrolling to a meal that doesn't exist
        if (!properMealOrder.includes(activeMeal)) {
            return;
        }

        const activeItem = this.state.items.find(
            (item) => activeDate.isSame(item.date, 'day') && item.mealType === activeMeal
        );

        // If we couldn't find our active item, we need to reset entirely.
        if (!activeItem) {
            return;
        }

        this.setState({ activeDate, activeMeal, activeItem });
    };

    next = (e) => {
        let { items, activeMeal, activeDate } = this.state;
        let activeItem = items.find((item) => item.mealType === activeMeal && item.date.isSame(activeDate, 'day'));
        let index = items.indexOf(activeItem) + 1;

        if (!items[index]) {
            return;
        }

        activeItem = items[index];

        this.setState(
            { activeItem, activeMeal: activeItem.mealType, activeDate: activeItem.date },
            this.centerScrollable
        );
    };

    prev = (e) => {
        let { items, activeMeal, activeDate } = this.state;
        let activeItem = items.find((item) => item.mealType === activeMeal && item.date.isSame(activeDate, 'day'));
        let index = items.indexOf(activeItem) - 1;

        if (!items[index]) {
            return;
        }

        activeItem = items[index];

        this.setState(
            { activeItem, activeMeal: activeItem.mealType, activeDate: activeItem.date },
            this.centerScrollable
        );
    };

    onClickItemImage = (item) => {
        let { activeMeal, activeDate } = this.state;
        const { startAddMeal } = this.context;

        if (item.populating) {
            return this.setState(
                {
                    thumped: { date: this.state.activeDate, mealType: this.state.activeMeal },
                },
                this.resetThumped
            );
        }

        if (activeMeal != item.mealType || !activeDate.isSame(item.date, 'day')) {
            this.setState({ activeItem: item, activeMeal: item.mealType, activeDate: item.date });

            return;
        }

        const liveMeals = (item.meals || []).filter((m) => !m.deleted);
        if (!liveMeals.length) {
            const options = {}

            if (item.mode === 'past') {
                options.auto_log = true;
                options.defaultMode = 'browser';
            } else {
                options.fullBrowserParams = {defaultTypes: ['recipe']};
            }

            return startAddMeal(item.date, item.mealType, options);
        }

        if (item.mode === 'past' || (item.mode === 'current' && item.meals[0] && item.meals[0].logged_portion)) {
            const { router } = this.context;

            router.push(`/log/${item.date.format('YYYY-MM-DD')}`);
            return;
        }

        const { showMealDetails } = this.context;

        showMealDetails(item.meals);
    };

    whichMealsAreIncluded = (profile) => {
        const { preferences = {}, family = [] } = profile || {};

        let breakfasts = preferences.breakfasts,
            lunches = preferences.lunches,
            dinners = preferences.dinners,
            snacks = preferences.snacks;

        family.forEach((member) => {
            breakfasts = breakfasts || member.breakfasts;
            lunches = lunches || member.lunches;
            dinners = dinners || member.dinners;
            snacks = snacks || member.snacks;
        });

        return { breakfasts, lunches, dinners, snacks };
    };

    getItems = (context, startDate, endDate, activeDate, activeMeal, defaultMealType) => {
        // const { user, meals, startDate, endDate, activeDate, activeMeal } = this.state;
        const { user, meals, recipes, foods, synced } = context;
        let items = [],
            curDate;
        const now = moment();

        if (!user) {
            return items;
        }

        const { inhibit_warnings_for_merchants = [] } = user.features || {};

        // What are the default meals we include in the feed?
        let defaultMealTypes = [];
        const { breakfasts = 5, lunches = 5, dinners = 5, snacks = 5 } = this.whichMealsAreIncluded(user);

        if (breakfasts) defaultMealTypes.push('Breakfast');
        if (snacks) defaultMealTypes.push('Snack');
        if (lunches) defaultMealTypes.push('Lunch');
        if (dinners) defaultMealTypes.push('Dinner');

        // If all meals are unselected, include all of them for show.
        if (!defaultMealTypes.length) {
            defaultMealTypes = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];
        }

        const defaultMealIndex = properMealOrder.indexOf(defaultMealType);

        const mealsIndexByDate = {};
        meals.forEach((meal) => {
            mealsIndexByDate[meal.date] = mealsIndexByDate[meal.date] || [];
            mealsIndexByDate[meal.date].push(meal);
        });

        // Find the daily rx
        const allDayRx = ((user && user.prescriptions) || []).filter((v) => v).find((p) => p.meal_type === 'all-day');

        // Provide each item with a copy of the per-meal prescription (useful later)
        const rxIndex = indexBy((user && user.prescriptions) || [], 'meal_type');
        properMealOrder.forEach((mealType) => {
            if (rxIndex[mealType]) return;

            rxIndex[mealType] = allDayRx ? computeDefaultMealRx(allDayRx, mealType, true) : {};
        });

        const { preferences = { avoidances: [] } } = user || {};
        const allContent = { ...recipes, ...foods };

        for (curDate = moment(startDate); curDate.isSameOrBefore(endDate, 'day'); curDate.add(1, 'day')) {
            let mealTypesThisDay = [];
            let mealTypesToAdd = defaultMealTypes.slice();
            let dayMode = 'future';
            let dateKey = curDate.format('YYYY-MM-DD');

            // Make sure that the current meal is always in the list
            if (curDate.isSame(now, 'day')) {
                dayMode = 'current';

                if (!mealTypesToAdd.includes(defaultMealType)) {
                    mealTypesToAdd.push(defaultMealType);
                }

                // mealTypesToAdd = properMealOrder.slice();
            } else if (curDate.isBefore(now, 'day')) {
                dayMode = 'past';

                mealTypesToAdd = properMealOrder.slice();
            }

            // If there's any other meals on this day, make sure they have a slot.
            const daysMeals = (mealsIndexByDate[dateKey] || []).filter((meal) =>
                ['fresh', 'leftover', 'note', 'food'].includes(meal.meal_type)
            );

            daysMeals.forEach((meal) => {
                if (properMealOrder.includes(meal.meal) && !mealTypesToAdd.includes(meal.meal)) {
                    mealTypesToAdd.push(meal.meal);
                }

                if (properMealOrder.includes(meal.meal) && !mealTypesThisDay.includes(meal.meal)) {
                    mealTypesThisDay.push(meal.meal);
                }
            });

            if (activeDate.isSame(curDate, 'day') && !mealTypesToAdd.includes(activeMeal)) {
                mealTypesToAdd.push(activeMeal);
            }

            // Make sure the mealTypesToAdd is sorted appropriately
            mealTypesToAdd = mealTypesToAdd.sort((a, b) => {
                const aI = properMealOrder.indexOf(a),
                    bI = properMealOrder.indexOf(b);

                if (aI > bI) return 1;
                if (aI < bI) return -1;

                return 0;
            });

            // We use the computed or explicit snack prescription by default
            let snackNeededNutrients = rxIndex['Snack'];

            // Does this day include Breakfast, Lunch AND Dinner?
            let planningAllFullMeals =
                mealTypesToAdd.filter((mealType) => ['Breakfast', 'Lunch', 'Dinner'].includes(mealType)).length === 3;
            let plannedAllFullMeals =
                mealTypesThisDay.filter((mealType) => ['Breakfast', 'Lunch', 'Dinner'].includes(mealType)).length === 3;

            let fullMeals = [],
                fullNutrients = {};

            // If we're going to add snacks to this day, we'll need to choose which nutrition envelope to compare it against.
            if (mealTypesToAdd.includes('Snack') && allDayRx && plannedAllFullMeals) {
                // Get all the non-snacks for the day
                fullMeals = daysMeals.filter((meal) => ['Breakfast', 'Lunch', 'Dinner'].includes(meal.meal));
                fullNutrients = getNutrientsForMeals(fullMeals, allContent, user.portion);

                // Compute the snack needed nutrients
                snackNeededNutrients = getNeededNutrients(fullNutrients, allDayRx.envelope);
            }

            mealTypesToAdd.forEach((mealType, i) => {
                let mode = dayMode;

                // We only add breakfast, lunch, dinner & snacks
                if (!properMealOrder.includes(mealType)) {
                    return;
                }

                const mealI = properMealOrder.indexOf(mealType);

                if (mode === 'current' && mealI < defaultMealIndex) {
                    mode = 'past';
                } else if (mode === 'current' && mealI > defaultMealIndex) {
                    mode = 'future';
                }

                let itemMeals = daysMeals.filter((m) => m.meal === mealType);
                let nutrients =
                    itemMeals.length && synced
                        ? getNutrientsForMeals(
                              itemMeals.filter((m) => !m.deleted),
                              allContent,
                              user.portion
                          )
                        : null;
                let ingredientTags = itemMeals.length && synced ? getIngredientTagsForMeals(itemMeals, allContent) : [];
                let mealTags =
                    itemMeals.length && synced
                        ? getTagsForMeals(
                              itemMeals.filter((m) => !m.deleted),
                              allContent
                          )
                        : [];

                let avoidances = ingredientTags.filter((tag) => preferences.avoidances?.includes(tag));

                let envelope = mealType === 'Snack' ? snackNeededNutrients : rxIndex[mealType].envelope;

                const nonDeletedMeals = itemMeals.filter((meal) => !meal.deleted);

                const mealsWithContent = nonDeletedMeals.filter(
                    (meal) =>
                        (meal.recipe_uuid &&
                            allContent[meal.recipe_uuid] &&
                            allContent[meal.recipe_uuid].type == 'recipe') ||
                        (meal.food_uuid && allContent[meal.food_uuid] && allContent[meal.food_uuid].type == 'food')
                );

                const { content } = getPrimaryMeal(itemMeals, allContent, allContent);

                let shouldShowDevations =
                    itemMeals.length &&
                    envelope &&
                    nutrients &&
                    nonDeletedMeals.length == mealsWithContent.length &&
                    !inhibit_warnings_for_merchants.includes(content?.merchant?.uuid);

                let mismatches = shouldShowDevations ? compareNutrientsToEnvelope(nutrients, envelope, ['FRU']) : null;

                let isGrabAndGo = mealTags.includes('Grab & Go') || ingredientTags.includes('Grab & Go');
                let isRestaurantDish = false,
                    isBrandProduct = false;

                itemMeals.forEach((m) => {
                    if (m.deleted) {
                        return;
                    }

                    if (m.meal_type === 'product') {
                        if (
                            allContent[m.product_uuid] &&
                            allContent[m.product_uuid].product_type === 'Restaurant Dish'
                        ) {
                            isRestaurantDish = true;
                        }

                        isBrandProduct = true;
                    }
                });

                let disclaimers = [
                    isGrabAndGo ? 'Grab & Go' : null,
                    isRestaurantDish ? 'Restaurant Dish' : null,
                    isBrandProduct ? 'Branded Food' : null,
                ].filter((v) => v);

                items.push({
                    meals: itemMeals,
                    date: moment(curDate),
                    mealType,
                    mode,
                    rx: rxIndex[mealType],
                    nutrients,
                    mismatches,
                    avoidances,
                    disclaimers,
                    orders: MealStore.getOrdersForMeals(itemMeals),
                });
            });
        }

        return items;
    };

    handleSwipe = (event) => {
        if (event.direction === 4) {
            this.prev();
        }

        if (event.direction === 2) {
            this.next();
        }
    };

    closeModal = () => {
        const { router, location } = this.context;

        if (location.query.signup) {
            delete location.query.signup;
            router.push(location);
        }

        this.setState({
            isCoachmarkVisible: null,
        });
    };

    toggleActiveFlipped = () => {
        const { activeFlipped } = this.state;

        this.setState({ activeFlipped: !activeFlipped });
    };

    goToCurrentMeal = () => {
        const { defaultMealType } = this.props;
        this.setActiveMeal(moment(), defaultMealType);
    };

    renderItem = (item, i) => {
        const { items, thumped, activeDate, activeMeal, activeItem, activeFlipped } = this.state;
        const { recipes, brands, startReplaceMeal } = this.context;
        const { defaultMealType } = this.props;

        let dateKey = item.date.format('YYYY-MM-DD');

        return (
            <MealCard
                item={item}
                key={i}
                ref={(el) => (item.element = el)}
                items={items}
                currentIndex={i}
                thumped={thumped}
                activeDate={activeDate}
                activeMeal={activeMeal}
                activeItem={activeItem}
                activeFlipped={activeFlipped}
                defaultMealType={defaultMealType}
                scrollToNextCard={this.next}
                onClickItemImage={this.onClickItemImage}
                toggleActiveFlipped={this.toggleActiveFlipped}
            />
        );
    };

    renderCoachmark = () => {
        const { isCoachmarkVisible } = this.state;
        const { user } = this.context;

        if (!isCoachmarkVisible) {
            return null;
        }

        return <FeedCoachmark closeModal={this.closeModal} user={user} />;
    };

    closeChangePassword = () => {
        const { location, router } = this.context;
        const { pathname, query, hash } = location;
        delete query['change-password'];

        router.push({ pathname, query, hash });
    };

    renderChangePassword = () => {
        const { location } = this.context;
        const { query } = location;

        if (!query['change-password']) {
            return;
        }
        return <ChangePasswordModal closeModal={this.closeChangePassword} />;
    };

    render() {
        const { items, activeDate, startDate, endDate, activeMeal, activeItem, showSplash } = this.state;
        const { isVisible, defaultMealType } = this.props;
        const { loaded, viewportWidth, isCordova } = this.context;

        let timeMode = 'present';
        const now = moment();

        if (now.isBefore(activeDate, 'day')) {
            timeMode = 'future';
        } else if (now.isAfter(activeDate, 'day')) {
            timeMode = 'past';
        }

        const nextPrevClasses = ['next-prev'];
        let isSafariMobile = false;

        if (viewportWidth <= 414 && !isCordova && platform.name === 'Safari') {
            nextPrevClasses.push('safari-fix');
            isSafariMobile = true;
        }

        return isVisible && (
            <div className="meal-feed meal-feed-bg" data-bg-mode={timeMode}>
                <div className="bg-container">
                    <div className="swipable-area">
                        <AddMissingMeal activeDate={activeDate} />

                        <SpringFeed
                            items={items}
                            activeDate={activeDate}
                            activeMeal={activeMeal}
                            activeItem={activeItem}
                            startDate={startDate}
                            endDate={endDate}
                            defaultMealType={defaultMealType}
                            nextCard={this.next}
                            prevCard={this.prev}
                            isSafariMobile={isSafariMobile}
                            onClickItemImage={this.onClickItemImage}
                        />

                        <footer className={classNames(nextPrevClasses)}>
                            <button className="prev-card-btn" onClick={this.prev}>
                                <i className="icon-chevron-left" />
                            </button>
                            <button className="next-card-btn" onClick={this.next}>
                                <i className="icon-chevron-right" />
                            </button>
                        </footer>
                    </div>

                    <div className="current-meal-btn-container">
                        <button
                            className="current-meal-btn"
                            data-visible={!(timeMode === 'present' && activeMeal === defaultMealType)}
                            onClick={this.goToCurrentMeal}
                        >
                            go to current meal
                        </button>
                    </div>

                    <FeedGoals />
                    {this.renderCoachmark()}
                    {this.renderChangePassword()}
                </div>
            </div>
        );
    }
}
