'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Select from '../../pro/components/Widgets/Select.react';
import Alert from '../../components/Widgets/Alert/Alert.react';
import Analytics from '../../utils/Analytics';
import { roundForHumans } from '../../utils/Math';
import { getLoggedServingsOfRecipe } from '../../utils/Meals';
import modalStyles from '../../jsx-styles/modals';

import '../../pro/components/Modals/Modals.scss';
import './EditMealServingsModal.scss';

export default class EditMealServingsModal extends Component {
    static propTypes = {
        meal: PropTypes.object,
        contents: PropTypes.object,
        scaling: PropTypes.number,
        onChangeParticipants: PropTypes.func,
        closeModal: PropTypes.func,
        participants: PropTypes.array,
        editableParticipants: PropTypes.bool,
    };

    static defaultProps = {
        editableParticipants: true,
    };

    static contextTypes = {
        isPro: PropTypes.bool,
        user: PropTypes.object,
        meals: PropTypes.array
    };

    constructor(props, context) {
        super(props, context);

        const { contents, mealDishes, plan, scaling, content } = props;

        const { meals } = context;
        const participants = props.participants.slice(); // Make a copy of the array

        let parent = null;
        let allMeals = [];
        let leftovers = [];
        let totalDays = 1;
        let dishes = [];

        if(mealDishes) {
             mealDishes.forEach(meal => {
                let dish = Object.assign({}, meal);
                if (meal.meal_type == 'leftover') {
                    parent = meals.find(item => item.uuid === meal.parent_uuid);
                    parent = plan ?
                        plan.items.find(item => item.id === meal.parent)
                        : meals.find(item => item.uuid === meal.parent_uuid);
                } else {
                    parent = meal;
                }
                leftovers = plan ?
                    plan.items.filter(item => item.parent === parent.id && !item.deleted)
                    : meals.filter(item => item.parent_uuid === parent.uuid && !item.deleted);
                allMeals = leftovers.slice();
                allMeals.unshift(parent);

                dish.parent = parent;
                dish.allMeals = allMeals;
                dish.leftovers = leftovers;
                dishes.push(dish);
            })

            totalDays = dishes[0].allMeals.length;
        } else if (scaling && content) {
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            totalDays = Math.floor(scaling * content.servings / needed);
        }

        if (totalDays > 5) {
            totalDays = 5;
        }

        this.state = {
            totalDays,
            originalTotalDays: totalDays,
            participants,
            dishes,
            batchOptions: this.getLeftoverOptionsFromParticipantsMeal(),

            alert: '',
        };
    }

    componentDidMount =  () => {
        const { meal } = this.props;
        Analytics.editMeal({"Meal UUID": meal ? meal.uuid : null});
    }

    isParticipating = (participant) => {
        const { participants } = this.state;

        const result = participants.find(p => {
            if (participant.uuid && p.uuid === participant.uuid) {
                return true;
            }

            if (p === participant) {
                return true;
            }

            return false;
        })

        return result ? true : false;
    }

    getLeftoverOptionsFromParticipantsMeal = () => {
        let options = [
            {label: '1 day', value: 1},
            {label: '2 days ', value: 2},
            {label: '3 days', value: 3},
            {label: '4 days', value: 4},
            {label: '5 days', value: 5},
        ];

        return options;
    }

    recalculateScaling = (dish, participants, content, totalDays) => {

        const { profile } = this.props;

        let { allMeals, parent } = dish;

        const servings = (content.servings || 1);
        let totalNeeded;

        const primaryUserIncluded = !!participants.find(participant => participant.uuid == profile.uuid);

        if (dish) {

            let baseAmount = 0;
            // Don't count logged amount from removed meals
            if (totalDays < allMeals.length) {
                allMeals = allMeals.slice(0, totalDays);
            }

            // Copy parent meals logged_amount to new meals
            if (totalDays > allMeals.length) {
                const loggedServings = getLoggedServingsOfRecipe(parent, content);
                baseAmount = (totalDays - allMeals.length) * loggedServings
            }

            const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion), 0);
            const primaryUserNeededServings = primaryUserIncluded ? allMeals.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, content), baseAmount) : 0;

            totalNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + primaryUserNeededServings;
        } else {
            const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
            totalNeeded = neededPerMeal * totalDays;
        }

        let scaling = Math.ceil(totalNeeded / servings);

        return scaling;
    }

    getRecipeScaling = () => {
        const { content } = this.props;
        const { participants, totalDays } = this.state;
        const servings = (content.servings || 1);
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        const totalNeeded = neededPerMeal * totalDays;

        let scaling = Math.ceil(totalNeeded / servings);

        return scaling;
    }

    refineParticipantNames = (participants) => {
        let i = 1;

        participants.forEach(member => {
            if (member.uuid) {
                return;
            }

            member.name = 'Guest #' + i;
            i++;
        });

        return participants;
    }

    onRemoveParticipant = (member) => {
        const { contents, meal } = this.props;
        let { participants, totalDay, dishes, totalDays } = this.state;

        // If this is a UUID'd member, then we'll have to remove them by ID instead.
        if (member.uuid) {
            member = participants.find(m => m.uuid === member.uuid);
        }

        // If there's not a UUID, then it's a guest, and we can find it by index
        const i = participants.indexOf(member);

        participants = participants.slice();
        participants.splice(i, 1);

        dishes.forEach((dish, index) => {
          dishes[index].scaling = this.recalculateScaling(dish, participants, contents[dish.recipe_uuid || dish.food_uuid], totalDays);
        });

        const batchOptions = this.getLeftoverOptionsFromParticipantsMeal();

        participants = this.refineParticipantNames(participants);

        this.setState({participants, dishes, batchOptions});
    }

    onAddParticipant = (member) => {
        const { contents, meal } = this.props;
        let { participants, totalDays, dishes } = this.state;

        participants = participants.slice(); // Make a copy of the array
        participants.push(member);

        dishes.forEach((dish, index) => {
          dishes[index].scaling = this.recalculateScaling(dish, participants, contents[dish.recipe_uuid || dish.food_uuid], totalDays);
        });

        const batchOptions = this.getLeftoverOptionsFromParticipantsMeal();

        participants = this.refineParticipantNames(participants);

        this.setState({participants, dishes, batchOptions});
    }

    onChangeNumberOfGuests = (guestCount) => {
        const { contents } = this.props;
        let { participants, totalDays, dishes } = this.state;

        const nonGuestParticipants = participants.filter(member => member.uuid);

        const guests = [...Array(guestCount).keys()].map(number => {
            return {uuid: "", name: 'Guest #' + (number + 1), portion: 1};
        });

        participants = nonGuestParticipants.concat(guests);

        dishes.forEach((dish, index) => {
          dishes[index].scaling = this.recalculateScaling(dish, participants, contents[dish.recipe_uuid || dish.food_uuid], totalDays);
        });

        this.setState({participants, dishes});
    }

    onChangeTotalDays = (totalDays) => {
        const { contents, meal, onChangeTotalDays } = this.props;
        let { participants, dishes } = this.state;

        dishes.forEach((dish, index) => {
          dishes[index].scaling = this.recalculateScaling(dish, participants, contents[dish.recipe_uuid || dish.food_uuid], totalDays);
        });

        this.setState({totalDays, dishes});
        onChangeTotalDays && onChangeTotalDays(totalDays);
    }

    onSelectNonParticipant = (nonParticipant) => {
        if (!nonParticipant) {
            return;
        }

        if (nonParticipant === 'guest') {
            return this.onAddGuest();
        }

        return this.onAddParticipant(nonParticipant);
    }

    onChangeParticipants = () => {
        const { participants, totalDays, originalTotalDays, dishes } = this.state;
        const { onChangeParticipants, meal } = this.props;

        if (!participants.length) {
            this.setState({alert: 'Please choose at least one person to eat this meal'});
            return;
        }


        Analytics.saveMeal({
            "Edited Batch": originalTotalDays != totalDays,
            "Edited Participans": this.props.participants.length != participants.length,
            "Days": totalDays,
            "Participants": participants.length,
            "Meal UUID": meal ? meal.uuid : null
        });

        onChangeParticipants(participants, dishes.length ? this.createScalingMap() : this.getRecipeScaling(), null, totalDays);
    }

    renderParticipant = (member, i) => {
        const { editableParticipants, profile, contents } = this.props;
        const { dishes } = this.state;
        const isEating = this.isParticipating(member);
        const onClickFunc = isEating ? this.onRemoveParticipant : this.onAddParticipant;

        let nameLabel;
        let portionText = `${member.portion} ${member.portion == 1 ? "serving" : "servings"}`
        const isPrimaryUser = profile.uuid == member.uuid;

        if (isPrimaryUser) {
            const firstServingsValues = dishes.length ? getLoggedServingsOfRecipe(dishes[0], contents[dishes[0].recipe_uuid || dishes[0].food_uuid]) : member.portion;
            const allSameServings = dishes.length ? dishes.every(dish => getLoggedServingsOfRecipe(dish, contents[dish.recipe_uuid || dish.food_uuid]) === firstServingsValues) : true;

            if (allSameServings) {
                portionText = `${firstServingsValues} ${firstServingsValues == 1 ? "serving" : "servings"}`
            } else {
                portionText = '';

                dishes.forEach((dish, index) => {
                    const servingsValue = getLoggedServingsOfRecipe(dish, contents[dish.recipe_uuid || dish.food_uuid]);
                    portionText+=`Dish ${index + 1}: ${servingsValue} ${servingsValue == 1 ? "serving" : "servings"}${dishes.length > index + 1 ? "," : ""} `

                })
            }
        }


        if (!editableParticipants) {
            nameLabel = <span className="name-no-edit">{i + 1}. {member.name} <span className="portion-label">{portionText}</span></span>;
        } else if (isPrimaryUser) {
            nameLabel = <span className="el-checkbox" onClick={editableParticipants ? () => onClickFunc(member) : null} data-active={isEating}>{member.name} <span className="portion-label">{portionText}</span></span>;
        } else {
            nameLabel = <button onClick={editableParticipants ? () => onClickFunc(member) : null}
                            className="el-checkbox" data-editable={editableParticipants}
                            data-active={isEating}>
                            {!editableParticipants ? <span>{i + 1}. </span> : null}
                            {member.name} <span className="portion-label">{portionText}</span>
                        </button>
        }



        return (
            <li key={i}>
                {nameLabel}
            </li>
        );
    }

    createScalingMap = () => {
        const { participants, totalDays, originalTotalDays, dishes } = this.state;
        let scaling = {};

        dishes.forEach((dish) => {
          scaling[dish.uuid || dish.id] = dish.scaling;
        });

        return scaling;
    }

    getAlerts = () => {
        const { contents, profile } = this.props;
        const { totalDays, dishes, participants } = this.state;

        let warnings = [];

        dishes.forEach((dish)=> {

            const {leftovers, allMeals} = dish;
            const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion), 0);
            let totalNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + allMeals.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, contents[meal.recipe_uuid || dish.food_uuid]), 0);
            const totalYield = dish.scaling * contents[dish.recipe_uuid || dish.food_uuid].servings;

            if ((totalDays - 1) < leftovers.length) {
                const dayDiff = leftovers.length - (totalDays - 1);
                totalNeeded -= (dayDiff * getLoggedServingsOfRecipe(dish, contents[dish.recipe_uuid || dish.food_uuid]));
            }

            if ((totalDays - 1) > leftovers.length) {
                const dayDiff = (totalDays - 1) - leftovers.length;
                totalNeeded += (dayDiff * getLoggedServingsOfRecipe(dish, contents[dish.recipe_uuid || dish.food_uuid]));
            }

            if(totalYield > totalNeeded) {
                warnings.push(`${contents[dish.recipe_uuid]?.title || contents[dish.food_uuid]?.name}: Scaled to ${totalYield} ${totalYield > 1 ? "servings" : "serving"} (${totalNeeded} ${totalNeeded > 1 ? "servings" : "serving"} needed)`);
            }

        })


        if (warnings.length) {
            return <Alert type="warning" description={<><strong>Please note, the following recipes have been adjusted to meet minimum yield requirements:</strong><ul>{warnings.map((warning, index) => <li key={index}>{warning}</li>)}</ul></>} />;
        }

        return;

    }


    getRecipeAlert = () => {
        const { content, profile } = this.props;
        const { totalDays, participants } = this.state;

        let warnings = [];

        const servingsNeeded = participants.reduce((total, member) => total + member.portion, 0);
        const totalNeeded = servingsNeeded * totalDays;
        const totalYield = this.getRecipeScaling() * content.servings;

        if(totalYield > totalNeeded) {
            warnings.push(`${content.title}: Scaled to ${totalYield} ${totalYield > 1 ? "servings" : "serving"} (${totalNeeded} ${totalNeeded > 1 ? "servings" : "serving"} needed)`);
        }

        if (warnings.length) {
            return <Alert type="warning" description={<ul>{warnings.map((warning) => <li>{warning}</li>)}</ul>} />;
        }

        return;

    }

    renderRecipeScaling = () => {
        const { content } = this.props
        const { totalDays, participants } = this.state;
        const scaling = this.getRecipeScaling();

        const servingsNeeded = participants.reduce((total, member) => total + member.portion, 0);
        const totalNeeded = servingsNeeded * totalDays;
        const totalYield = scaling * content.servings;
        const remainder = totalYield - totalNeeded;

        const servingsPerDay = servingsNeeded;
        const totalLeftoverServings = servingsPerDay * (totalDays - 1);

        return (
            <>
            <h2>{content.title}: <em>{totalYield}</em> servings</h2>
            {totalDays > 1 ? <p>Leftovers: {totalLeftoverServings} servings</p> : null}
            </>
        )
    }

    render() {
        const { contents, closeModal, profile, editableParticipants, content } = this.props;
        const { alert, totalDays, participants, batchOptions, dishes } = this.state;
        const { isPro, user } = this.context;

        const nonGuestParticipants = participants.filter(member => member.uuid);
        let hasNoLeftoversTag = [];
        const notParticipating = [];

        if (contents) {
            hasNoLeftoversTag = dishes.filter(
                dish => contents[dish.recipe_uuid || dish.food_uuid]?.tags.includes('No Leftovers')
            ).map(dish => contents[dish.recipe_uuid]?.title || contents[dish.food_uuid]?.name);
        }

        const primaryUserIncluded = this.isParticipating(profile);

        if (!primaryUserIncluded) {
            notParticipating.push({
                uuid: profile.uuid,
                name: profile.first_name || profile.name || profile.email,
                portion: profile.portion,
            });
        }

        profile.family.forEach(member => {
            if (!this.isParticipating(member)) {
                notParticipating.push(member);
            }
        });

        // Do we have a guest already?
        const guestCount = participants.filter(p => !p.uuid).length;

        const guestOpts = [...Array(31).keys()].map(number => {
            return {label: number,  value: number};
        });




        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit Meal Participants"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>


                <div className="el-modal-container el-modal2-container edit-meal-servings-container">
                    <header>
                        <h2>Edit Meal Servings</h2>
                        <button onClick={closeModal} className="el-modal-close-x">
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container">
                        <div className="yield-info">
                            <h2>Total Yield for Meal</h2>
                            {dishes.map((dish, index) => {

                                const {leftovers, allMeals} = dish;

                                const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion), 0);
                                const primaryUserNeededServings = primaryUserIncluded ? allMeals.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, contents[meal.recipe_uuid || meal.food_uuid]), 0) : 0;
                                let totalNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + primaryUserNeededServings;
                                const totalYield = totalNeeded ? (dish.scaling || 1) * contents[dish.recipe_uuid || dish.food_uuid].servings : 0;
                                const remainder = totalYield - totalNeeded;

                                let totalLeftoverServings = (otherUsersMealServingsNeededPerDay * (totalDays - 1)) + leftovers.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, contents[meal.recipe_uuid || meal.food_uuid]), 0);

                                const dishLoggedServings = getLoggedServingsOfRecipe(dish, contents[dish.recipe_uuid || dish.food_uuid]);

                                if ((totalDays - 1) < leftovers.length) {
                                    const dayDiff = leftovers.length - (totalDays - 1);
                                    totalNeeded -= (dayDiff * dishLoggedServings);
                                    totalLeftoverServings -= (dayDiff * dishLoggedServings);
                                }

                                if ((totalDays - 1) > leftovers.length) {
                                    const dayDiff = (totalDays - 1) - leftovers.length;
                                    totalNeeded += (dayDiff * dishLoggedServings);
                                    totalLeftoverServings += (dayDiff * dishLoggedServings);
                                }
                                return (
                                    <React.Fragment key={index}>
                                    <h2>{contents[dish.recipe_uuid]?.title || contents[dish.food_uuid]?.name}:</h2>
                                    <p><em>{totalYield}</em> {totalYield > 1 ? "servings" : "serving"} {totalDays > 1 ? ` (includes ${totalLeftoverServings} ${totalLeftoverServings > 1 ? "leftovers" : "leftover"})` : null}</p>
                                    </React.Fragment>
                                )

                            })}

                            {dishes.length == 0 ? this.renderRecipeScaling() : null}

                        </div>
                        <div className="edit-days">

                        {dishes.length ? this.getAlerts() : this.getRecipeAlert()}

                        <div className="days-select">
                            <h6>Make enough for</h6>
                            <div data-testid="days-dropdown">
                                <div className="days-dropdown-wrapper">
                                <Select defaultClassName="el-select-container small" dropdownIcon={"icon-down-arrow"} value={totalDays}
                                    options={batchOptions}
                                    onChange={this.onChangeTotalDays}>
                                </Select>
                                </div>
                            </div>
                        </div>

                        {totalDays >= 4 ?
                            <Alert type="info" description={<>Following food safety guidelines, it is best to consume cooked food and leftovers between 3-4 days. It is recommended that anything consumed after these guidelines be frozen until to ready to eat.</>} />
                        : null}
                        </div>
                        {hasNoLeftoversTag.length && totalDays > 1 ?
                            <>
                                <Alert type="info">
                                    {`Typically, ${hasNoLeftoversTag.join(', ')} ${hasNoLeftoversTag.length > 1 ? "aren't" : "isn't"} suited for storing as leftovers due to ${hasNoLeftoversTag.length > 1 ? "their" : "its"} limited shelf life.`}
                                </Alert>
                                <Alert type="warning">
                                    {`The recipe you have selected (${hasNoLeftoversTag.join(', ')}) ${hasNoLeftoversTag.length > 1 ? "are" : "is"} tagged as not suitable for batch cooking or storing as leftovers due to its limited shelf life.
                                    If you still wish to continue, please ensure all food safety guidelines are followed.`}
                                </Alert>
                            </>
                        : null}
                        {content?.tags.includes('No Leftovers') && totalDays > 1 ?
                            <Alert type="info">
                                {`Typically, ${content.title} isn't suited for storing as leftovers due to its limited shelf life.`}
                            </Alert>
                        : null}

                        <div className="edit-meal-servings">
                            <h5>Who is eating?</h5>
                            <ul>
                                {nonGuestParticipants.map(this.renderParticipant)}
                                {editableParticipants ? notParticipating.map(this.renderParticipant) : null}
                                {!isPro ?
                                    <div className="participants-select">
                                     <div>
                                        <div className="participants-dropdown-wrapper">
                                        <Select showAbove={true} defaultClassName="el-select-container small" dropdownIcon={"icon-down-arrow"} value={guestCount}
                                            options={guestOpts}
                                            onChange={this.onChangeNumberOfGuests}>
                                        </Select>
                                        </div>
                                    </div>
                                    <h6>Guests <span className="portion-label">1 serving each</span></h6>
                                    </div>
                                : null}
                            </ul>
                        </div>

                        {alert ?
                            <Alert type="error" description={alert} />
                        : null}
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={closeModal}>Cancel</button>
                        <button className="el-modal-ok-btn" onClick={this.onChangeParticipants}>Save</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
