'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Alert from '../../Widgets/Alert/Alert.react';
import TextArea from '../../Input/TextArea.react';

import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';
import Analytics from '../../../utils/Analytics';
import { flattenIngredients } from '../../../utils/Recipe';
import { getRecipeEventProperties } from '../../../utils/Content';

import './QuickAddIngredients.scss';

export default class QuickLogAiModal extends Component {
    static propTypes = {
        onLogFoods: PropTypes.func,
    };


    static contextTypes = {
        editSessionId: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            input: "",
            showModal: props.showModal || false,
            loading: false
        };
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        const { onCloseModal } = this.props;

        onCloseModal && onCloseModal();
        this.setState({showModal: false, input: '', error: null, loading: false, delay: false});
    }

    onChange = (event) => {
        this.setState({ input: event.target.value });
    };

    importRecipe = async () => {
        let { input } = this.state;
        const { groupIndex, details, onLogFoods } = this.props;
        const { editSessionId } = this.context;

        input = input.trim();

        if (!input) {
            this.setState({ error: 'Please describe your meal by typing it into the text field above.' });
            return;
        }

        this.setState({ loading: true, error: false, delay: false });
        let timeoutId = setTimeout(() => this.setState({ delay: true }), 30000);

        const payload = { text: input };

        const response = await AuthStore.fetch(getConfig('recipe_api') + '/import-recipe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=import/recipe/text/1' },
            body: JSON.stringify(payload),
        });

        if (!response?.links.self.href) {
            Analytics.quickAddIngredientsError({
                ...payload,
                error: job.output?.error,

                'Job ID': job.uuid,
                'Edit Session ID': editSessionId,
            });

            this.setState({
                loading: false,
                error: job?.output?.error || 'unknown error',
                delay: false,
            });
            return;
        }

        Analytics.startQuickAddIngredients({
            ...payload,
            'Job ID': response.uuid,
            'Edit Session ID': editSessionId,
        });

        let jobUrl = getConfig('recipe_api') + response?.links.self.href;
        let job = await AuthStore.fetch(jobUrl);
        let governor = 500;

        while (job?.status !== 'completed' && job?.status !== 'failed' && governor-- > 0) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            job = await AuthStore.fetch(jobUrl);

            if (!this.state.showModal) {
                // user closed the modal, abort and ignore.
                Analytics.cancelQuickAddIngredients({
                    ...payload,
                    'Job ID': job.uuid,
                });
                clearTimeout(timeoutId);
                return;
            }
        }

        if (job?.status !== 'completed') {
            Analytics.quickAddIngredientsError({
                ...payload,
                error: job.output?.error,
                'Job ID': job.uuid,
                'Edit Session ID': editSessionId,
            });
            this.setState({ loading: false, error: job?.output?.error || 'unknown error', delay: false });
            return;
        }

        const { recipe, details: recipeDetails } = job?.output;


        onLogFoods(recipeDetails);

        Analytics.quickAddIngredientsSuccess({
            ...payload,
            ...getRecipeEventProperties(recipe, recipeDetails),
            'Job ID': job.uuid,
            'Edit Session ID': editSessionId,
        });

        this.setState({ showModal: false, loading: false, error: false, delay: false, input: '' });

        clearTimeout(timeoutId);
    };

    renderLoader = () => {
        const { loading } = this.state;

        if (!loading) {
            return;
        }

        return (
            <div className="ingredients-loading">
                <i className="icon-spinner2" />
            </div>
        );
    };

    renderModal = () => {
        const { showModal, input, error, loading, delay } = this.state;

        if (!showModal) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={() => false}
                closeModal={() => false}
                contentLabel="Describe Your Meal"
                className={`el-modal el-modal3 el-modal3-centered`}
                overlayClassName={`el-modal-overlay`}
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal3-container">
                    {loading ? (
                        <div className="el-modal-loading el-fonts ingredients-loader" data-testid="ingredients-loader">
                            <h4>Please wait</h4>
                            <i className="icon-spinner2" />
                            <span className="loading-text">Analyzing ingredients</span>

                        </div>
                    ) : (
                        <>
                            <header>
                                <h2>Describe Your Meal</h2>
                                <button className="el-modal-close-x" onClick={this.closeModal}>
                                    <i className="icon-close-x" />
                                    <span className="assistive-text">Close Modal</span>
                                </button>
                            </header>
                            <div className="el-modal-body-container el-modal3-body-container el-form el-fonts">
                                <TextArea
                                    maxLength={5000}
                                    textareaClassName="quick-add-ingredients-textarea"
                                    placeholder={"Describe your meal by using text or voice using the microphone button on your keyboard.\n\nExample: 1 cheeseburger, small fries, 1 cup of broccoli, 1 cup of milk"}
                                    value={input}
                                    onChange={this.onChange}
                                />
                                {error && <Alert type="error">{error}</Alert>}
                            </div>
                        </>
                    )}

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>
                            Cancel
                        </button>
                        <button disabled={loading} className="el-modal-ok-btn" onClick={this.importRecipe}>
                            Log
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    };

    render() {

        return (
            <>
                <button className="add-food-btn quick-log-btn" onClick={this.openModal}>
                    Quick Log <button className="add-food-btn ai-false-btn">AI</button>
                </button>
                {this.renderModal()}
            </>
        );
    }
}
