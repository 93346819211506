'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import store from 'store';
import moment from 'moment';
import uuidGen from 'uuid';
import Helmet from 'react-helmet';
import debounce from 'lodash.debounce';

import MobileGrid from './Editor/MobileGrid.react';
import MenuStats from './Editor/MenuStats.react';
import DietitianBanner from './Editor/DietitianBanner.react';
import PlanToPdfButton from './Editor/PlanToPdfButton.react';
import MealDetails from './Editor/MealDetails.react';

import AddSwapRecipe from '../../../components/Search/Modals/AddSwapRecipe.react';
import LeftoverOffsetsModal from '../../../components/Planner/Modals/LeftoverOffsetsModal.react';
import ConfirmOverwriteModal from './Editor/ConfirmOverwriteModal.react';
import RecommendModal from '../Modals/RecommendModal.react';
import AgreementModal from '../Modals/AgreementModal.react';
import GroceriesModal from './Editor/GroceriesModal.react';
import NutritionModal from './Editor/NutritionModal.react';
import PublishingTools from './Editor/PublishingTools.react';
import SubscriptionRequired from './Editor/SubscriptionRequired.react';
import PickDateModal from './Editor/PickDateModal.react';
import EditMealServingsModal from '../../../components/Recipes/EditMealServingsModal.react';
import MealRepeatModal from './Editor/MealRepeatModal.react';
import ProfileModal from './Editor/ProfileModal.react';
import LogPortionsModal from '../../../components/Planner/Modals/LogPortionsModal.react';
import FavoriteButton from '../../../components/Widgets/FavoriteButton.react';
import SharePopup from '../../../components/Widgets/SharePopup.react';

import UserStore from '../../../stores/UserStore';
import BoardStore from '../../../stores/BoardStore';
import BoardActions from '../../../actions/BoardActions';
import PlanStore from '../../../stores/PlanStore';
import PlanActions from '../../../actions/PlanActions';
import MealStore from '../../../stores/MealStore';
import MealActions from '../../../actions/MealActions';
import GroceryStore from '../../../stores/GroceryStore';
import GroceryActions from '../../../actions/GroceryActions';
import AuthStore from '../../../stores/AuthStore';

import { createMealsForPlan, getAssetsForMeals, getParticipantsForMeal,
         getParticipantsForProfileByMealType, isParticipating, getPrimaryMeal, getServingsNeededWithLoggedServings, getLoggedServingsOfRecipe } from '../../../utils/Meals';
import { generateOffsets, getMealAndLeftovers, getBatchInfo, recalculateScaling } from '../../../utils/proMeals';
import { fetchDocumentsById, updateCachedDocuments, createNewDocument } from '../../../utils/Content';
import { analyzePlan, fetchVirtualPlan, deleteVirtualPlan, computeAverageNutrients } from '../../../utils/Plans';
import { getConfig } from '../../../utils/Env';
import { computeDefaultMealRx, getNeededNutrients, compareNutrientsToEnvelope, getNutrientsForMeals,
         addDefaultAddSwapTags, addDefaultAddSwapFilters, addNutrientSet, limitEnvelopeByMaximums } from '../../../utils/Nutrition';
import { addRecentlyEditedPlan, removeRecentlyEditedPlan } from '../../utils/Recent';
import { inquire } from '../../../utils/Enforcer';
import { isMealInGroceries } from '../../../utils/Grocery';
import { roundForHumans } from '../../../utils/Math';
import { conditionNamesToSpecialName } from '../../../utils/Conditions';
import { isSunbasketFood } from '../../../utils/Sunbasket';
import Analytics from '../../../utils/Analytics';

import './Editor.scss';

const validMealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

// This contains a list of all the meal plans for which all the assets have been loaded.
// This helps us save time when reloading.
const _loadedPlans = {};

export default class Editor extends Component {
    static propTypes = {
        uuid: PropTypes.string,
        patient: PropTypes.object,
        location: PropTypes.object,
        closeModal: PropTypes.func,
        onSavePlan: PropTypes.func,

        firstRun: PropTypes.bool,
        showAddToPlanner: PropTypes.bool,
        showPdfPrint: PropTypes.bool,
        editableParticipants: PropTypes.bool,
        showConsumerFavoriteButton: PropTypes.bool,
    };

    static defaultProps = {
        firstRun: false,
        showAddToPlanner: false,
        showPdfPrint: false,
        editableParticipants: true,
        hideWarnings: false,
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
        confirm: PropTypes.func.isRequired,
        isPro: PropTypes.bool,
        isMobile: PropTypes.bool,
        isCordova: PropTypes.bool,
        replaceMealPlan: PropTypes.func,
        showLoginForm: PropTypes.func,
    };

    static childContextTypes = {
        user: PropTypes.object,
        plan: PropTypes.object,
        patient: PropTypes.object,
        profile: PropTypes.object,

        // Secondary assets
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        brands: PropTypes.object,
        merchants: PropTypes.object,
        synced: PropTypes.bool,
        leftoversEnabled: PropTypes.bool,
        editableParticipants: PropTypes.bool,
        mismatches: PropTypes.object,

        // Add/Swap context
        addSwapContext: PropTypes.object,

        // Add/Swap functions & options
        showMealDetails: PropTypes.func,
        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        startRepeatMeal: PropTypes.func,
        startRescheduleMeal: PropTypes.func,

        onSelectRecipe: PropTypes.func,
        onSelectCombo: PropTypes.func,
        onSelectProducts: PropTypes.func,
        onSelectFood: PropTypes.func,
        alwaysSelectFood: PropTypes.bool,

        // Misc Functions
        onModifyMeals: PropTypes.func,
        onRemoveMeal: PropTypes.func,
        onRemoveMeals: PropTypes.func,

        rescheduleMeals: PropTypes.func,
        editMealBatches: PropTypes.func,

        onSelectFrequentlyUsed: PropTypes.func,
        getDefaultAddSwapSettings: PropTypes.func,
        getLeftoverOffsetOverlaps: PropTypes.func,
        hideWarnings: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const dateStart = moment();
        const user = UserStore.getUser();

        const profile = this.getProfileFromProps(props, user);

        this.state = {
            // Core plan data
            plan: null,
            boardContent: null,
            boards: [],
            maxOffset: 0, // number of days this meal plan is for
            dirty: false,
            dirtySinceLastSave: false,

            // User Data
            user,
            profile,

            // User Capabilities
            canWrite: false,
            canPublish: false,

            // Secondary assets
            recipes: {},
            details: {},
            foods: {},
            brands: {},
            groceries: [],
            mismatches: {},
            nutrients: {},
            synced: false,

            addSwapContext: null,

            totalDaysSet: 0,
            visibleOffset: 0,
            mealTypesToShow: ['Dinner'],

            // Planning state
            dateStart,
            dateSet: false, // no, the date has not been set.

            // Modalities
            loading: true,
            saving: false,
            saveButtonSaving: false,
            saved: false,
            loadFailure: null, // string reason why we can't load
            lastSaved: null,
            leftoversEnabled: false,
            maxLeftoverDays: 1,

            working: false,
            saveError: false,
            needBAA: false,
        };

        this.resetLastSaved = debounce(this.resetLastSaved, 5000);
        this.clearDeleted = debounce(this.clearDeleted, 3000);
        this.debounceAutosave = debounce(this.autosave, 1500);
    }

    getChildContext = () => {
        const { user, profile, plan, mismatches, recipes, details, foods, brands, merchants,
                addSwapContext, leftoversEnabled, synced } = this.state;
        const { patient, editableParticipants, hideWarnings } = this.props;

        return {
            plan,
            user,
            patient,
            profile,
            mismatches,

            recipes,
            details,
            foods,
            brands,
            merchants,
            synced,
            leftoversEnabled,
            editableParticipants,

            addSwapContext,
            hideWarnings,

            onModifyMeals: this.onModifyMeals,
            onRemoveMeal: this.onRemoveMeal,
            onRemoveMeals: this.onRemoveMeals,

            onSelectRecipe: this.onSelectRecipe,
            onSelectCombo: this.onSelectCombo,
            onSelectProducts: this.onSelectProducts,
            onSelectFood: this.onSelectFood,
            alwaysSelectFood: true,

            rescheduleMeals: this.rescheduleMeals,
            editMealBatches: this.editMealBatches,
            showMealDetails: this.showMealDetails,
            startAddMeal: this.startAddMeal,
            startReplaceMeal: this.startReplaceMeal,
            startRepeatMeal: this.startRepeatMeal,
            startRescheduleMeal: null,

            onSelectFrequentlyUsed: null,
            getDefaultAddSwapSettings: this.getDefaultAddSwapSettings,
            getLeftoverOffsetOverlaps: this.getLeftoverOffsetOverlaps

        };
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.loadPlan();
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    resetLastSaved = () => {
        this.setState({lastSaved: null});
    }

    scrollable = null;

    realizeScrollable = (el) => {
        this.scrollable = el;
    }

    getProfileFromProps = (props, user) => {
        const { patient, location = {} } = props;

        let altUser = false, altPatient = false, type = patient ? 'patient' : 'user';

        const query = location.query || {};

        if (query.altUser) {
            altUser = UserStore.getAlternateUser();
            type = 'alternate-user';
        } else if (query.altPatient) {
            altUser = UserStore.getAlternateUser();
            altPatient = true;
            type = 'alternate-patient';
        }

        // Try #1 - Are we using the alternate user?
        // Try #2 - Are we using a patient?
        // Try #3 - Use the currently logged in user
        // Fallback - Use a General Healthy Diet
        let {
            preferences = {diets: [], avoidances: [], exclude_foods: [], hide_nutrition: false, leftovers_enabled: true, max_leftover_days: 1},
            conditions = ['General Healthy Diet'],
            prescriptions = [],
            family = [],
            ...rest
        } = altUser || patient || user || {};

        // Clone preferences, so we don't cause side effects
        preferences = JSON.parse(JSON.stringify(preferences));

        if (patient) {
            // We should ignore hide_nutrition and inhibit_swap since we're their RD in this context
            preferences.rd_override = true;
        }

        // Be doubly sure that we're not causing unwanted side-effects
        return JSON.parse(JSON.stringify({
            type,
            altPatient,
            preferences,
            conditions,
            prescriptions,
            family,
            ...rest
        }));
    }

    inquiryPromise = null;

    fetchPermissions = () => {
        const { uuid } = this.props;

        // First, inquire to see if we have write and publish permission.
        let inquiries = [
            {
                action: 'publish',
                resource: uuid,
            },
            {
                action: 'write',
                resource: uuid,
            },
            {
                action: 'write',
                resource: '/plans'
            }
        ];

        return this.inquiryPromise = inquire(inquiries).then(decisions => {
            let canPublish = decisions.filter(d => d.action == 'publish' && d.resource == uuid)[0];
            let canWrite   = decisions.filter(d => d.action == 'write'   && d.resource == uuid)[0];
            let canCreate  = decisions.filter(d => d.action == 'write'   && d.resource == '/plans')[0];

            this.setState({
                canPublish: canPublish && canPublish.decision === 'permit',
                canWrite: canWrite && canWrite.decision === 'permit',
                canCreate: canCreate && canCreate.decision === 'permit',
            }, () => this.inquiryPromise = null);

            return { canPublish, canWrite, canCreate };
        });
    }

    getServingsNeeded = (mealType) => {
        const { profile } = this.state;

        let servingsNeeded = 0;

        getParticipantsForProfileByMealType(profile, mealType).forEach(member => servingsNeeded += member.portion);

        return Math.ceil(servingsNeeded);
    }

    editMealBatches = (meal) => {
        const { plan } = this.state;
        const { router } = this.context;
        const { location } = this.props;

        // Is this meal a child of another meal? Find the parent
        meal = meal.parent
             ? plan.items.find(m => m.id === meal.parent)
             : meal;

        const { pathname, query, hash } = location;

        query.editMeal = meal.id;

        router.push({pathname, query, hash});
    }

    onUserStoreChange = () => {
        let { canWrite, canPublish } = this.state;

        const newUser = UserStore.getUser();

        const profile = this.getProfileFromProps(this.props, newUser);

        this.setState({
            user: newUser,
            profile,
            canWrite,
            canPublish,
        }, () => {
            if (this.state.plan) {
                this.initializePlan();
            }
        });
    }

    closeLogPortionsModal = () => {
        this.setState({logPortionsFor: null, logPortionsParams: null});
    }

    closeAddSwapModal = () => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        delete query.swapRecipe;
        delete query.addRecipe;

        router.push({pathname, hash, query});

        this.setState({
            logPortionsFor: null,
            addSwapContext: null,
            replaceMeals: null,
            leftoverOverlaps: null,
            leftoverParams: null,
        });
    }

    closeMealDetailsModal = () => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        delete query.offset;
        delete query.mealType;

        router.push({pathname, hash, query});
    }

    closeEditMealBatches = () => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        delete query.editMeal;

        router.push({pathname, hash, query});

        this.setState({
            leftoverOverlaps: null,
            leftoverParams: null,
        });
    }

    closeConfirmOverwriteModal = (closeAll = false) => {
        if (closeAll === true) {
            return this.closeModal();
        }

        this.setState({isConfirmOverwriteOpen: false});
    }

    closeModal = () => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        delete query.swapRecipe;
        delete query.addRecipe;
        delete query.offset;
        delete query.mealType;
        delete query.sendToPatient;
        delete query.showGroceries;
        delete query.showNutrition;
        delete query.pickDate;
        delete query.showProfile;
        delete query.editMeal;

        router.push({pathname, hash, query});

        this.setState({
            dateSet: false,
            editMealBatches: null,
            leftoverOverlaps: null,
            leftoverParams: null,
            isConfirmOverwriteOpen: false,
            confirmOverlaps: null,
            isRepeatMealOpen: false,
            mealsToReschedule: null,
            mealsToRepeat: null,
        });
    }

    closeLeftoverOffsetsModal = () => {
        this.setState({leftoverOverlaps: null, leftoverParams: null});
    }

    // What's our maximum offset?
    getPlanMaxOffset(plan) {
        let maxOffset = 0;

        plan.items.forEach(item => {
            if (item && item.offset > maxOffset) {
                maxOffset = parseInt(item.offset);
            }
        });

        if (!(plan.items && plan.items.length > 0)) {
            maxOffset = 2;
        }

        return maxOffset;
    }

    sortMealTypes = (a, b) => {
        const aI = validMealTypes.indexOf(a),
              bI = validMealTypes.indexOf(b);

        if (aI > bI) return 1;
        if (aI < bI) return -1;
        return 0;
    }

    // returns the unique meal names used in this plan.
    getPlanMealTypes(plan) {
        const types = [];

        plan.items.forEach(item => {
            if (validMealTypes.includes(item.meal) && !types.includes(item.meal)) {
                types.push(item.meal);
            }
        });

        if (types.length <= 0) {
            types.push('Dinner');
        }

        return types.sort(this.sortMealTypes);
    }

    getBoardContentByUuid = (uuid) => {
        const boards = BoardStore.getBoardsByResourceId(uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === uuid
            );

            return carry.concat(bcs);
        }, []);

        return contents[0] ? contents[0] : null;
    }

    setPlanFromLocalStorage = (plan, edited, boardContent) => {
        this.fetchPermissions();

        this.setState({
            plan,
            dirty: edited ? true : false,
            dirtySinceLastSave: edited ? true : false,
            loading: false,
            maxOffset: this.getPlanMaxOffset(plan),
            mealTypes: this.getPlanMealTypes(plan),
            boardContent: boardContent,
        }, this.initializePlan);

        Analytics.customizePlan(plan, boardContent ? {'Recommended By': boardContent.author_name} : {});
    }

    setPlanFromDb = (uuid, plan, boardContent) => {
        // Discard saved data for this plan
        store.remove('menu-edited-' + uuid)
        // Have we already loaded this meal plans assets? Are all the assets already in memory?
        if (!_loadedPlans[uuid]) {

            this.fetchPermissions();

            // Mark that we've loaded this one from the db entirely.
            _loadedPlans[uuid] = true;

            // Fetch the plan, embedding its assets, then use that data to hydrate the document cache
            AuthStore.fetch(getConfig('recipe_api') + '/plans/' + uuid + '?embed=assets', null, true).then(
                plan => {
                    if (!boardContent) {
                        boardContent = this.getBoardContentByUuid(plan.uuid)
                    }

                    updateCachedDocuments(plan.assets);
                    delete plan.assets;
                    updateCachedDocuments([plan]);

                    Analytics.customizePlan(plan, boardContent ? {'Recommended By': boardContent.author_name} : {});

                    // Make a copy so we don't pollute the cached documents pool (just yet anyway).
                    plan = JSON.parse(JSON.stringify(plan));

                    this.setState({
                        plan,
                        maxOffset: this.getPlanMaxOffset(plan),
                        mealTypes: this.getPlanMealTypes(plan),
                        loading: false,
                        dirty: false,
                        loadFailure: false,
                        boardContent: boardContent,
                    }, this.initializePlan);
                },
                () => {
                    if (!this.state.user) {
                        const { showLoginForm } = this.context;

                        // Are we logged in? Perhaps we need to login.
                        const loginFormOpts = {
                            defaultMode: 'sign-in',
                            signupLinkSwitchesModes: true,
                            onCompleteSignUp: this.loadPlan,
                            onCompleteSignIn: this.loadPlan,
                            inhibitCloseModal: true,
                            offModalCta: 'You are one more step away from finding delicious meals just for you.',
                        };

                        showLoginForm && showLoginForm(loginFormOpts);
                    }

                    _loadedPlans[uuid] = false;
                    this.setState({loadFailure: 'Plan not found'});
                }
            );
            return;
        }

        // Otherwise try to load it from the database.
        fetchDocumentsById([uuid]).then(documents => {
            if (!documents[0]) {
                this.setState({loadFailure: 'Plan not found'});
                return;
            }

            // Make a copy so we don't pollute the cached documents pool (just yet anyway).
            plan = JSON.parse(JSON.stringify(documents[0]));

            if (!boardContent) {
                boardContent = this.getBoardContentByUuid(plan.uuid)
            }

            Analytics.customizePlan(plan, boardContent ? {'Recommended By': boardContent.author_name} : {});

            this.fetchPermissions();

            this.setState({
                plan,
                maxOffset: this.getPlanMaxOffset(plan),
                mealTypes: this.getPlanMealTypes(plan),
                loading: false,
                dirty: false,
                loadFailure: false,
                boardContent: boardContent,
            }, this.initializePlan);
        });
    }

    loadPlan = () => {
        const { confirm } = this.context;
        let { uuid } = this.props;

        // Do we have a copy that has been pre-modified?
        // Do we have a virtual plan? It's really cheap to check...
        const boardContent = this.getBoardContentByUuid(uuid);

        let edited = store.get('menu-edited-' + uuid),
            plan = !boardContent?.dietitian_recommendation && (edited || fetchVirtualPlan(uuid));

        if (plan && plan.replaced_by) {
            uuid = plan.replaced_by;
        } else if (plan && plan.links) {
            confirm(
                <p>You have previously unsaved changes to this plan. Would you like to restore them? </p>,
                () => this.setPlanFromLocalStorage(plan, edited, boardContent),
                () => this.setPlanFromDb(uuid, plan, boardContent),
                {acceptText: 'Yes, restore changes', rejectText: 'No, discard changes', rejectDefaultClass: "el-modal-ok-btn"},
            );
            return
        } else if (plan) {
            this.setPlanFromLocalStorage(plan, edited, boardContent);
            return;
        }
        this.setPlanFromDb(uuid, plan, boardContent);
    }

    updateEditedCopy = (plan) => {
        // Expire stored copy of the meal plan for 42 days.
        store.set('menu-edited-' + plan.uuid, plan, new Date().getTime() + 1000 * 3600 * 24 * 42); // expires after 42 days

        const { patient } = this.props;

        if (patient) {
            addRecentlyEditedPlan(plan, patient);
        }

        this.setState({dirtySinceLastSave: true});
    }

    onRevertPlan = () => {
        const { plan } = this.state;

        // Remove the plan from local storage.
        store.remove('menu-edited-' + plan.uuid);

        removeRecentlyEditedPlan(plan);

        this.setState({synced: false, dirtySinceLastSave: false});

        // Now reload
        this.loadPlan();
    }

    savePlan = (plan) => {
        // eslint-disable-next-line no-unused-vars
        const { uuid, links, type, created, ...rest } = plan;

        return AuthStore.fetch(getConfig('recipe_api') + plan.links.self.href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; schema=plan/1',
                'Accept': 'application/json; schema=plan/1',
            },
            body: JSON.stringify(rest),
        });
    }

    shouldCopyPlan = () => {
        const { plan, user } = this.state;

        return plan.owner !== user.uuid;
    }

    markSaved = () => {
        this.setState({saved: true});

        setTimeout(function() {
          this.setState({saved: false});
        }.bind(this), 120000);

    }

    onClickSaveButton = () => {
        const { confirm } = this.context;
        const { user } = this.state;

        if (user.dietitian && !this.shouldCopyPlan()) {
            confirm(
                <p> <strong>If you've previously recommended this plan to one or more {user.practice_type == "dietetics" ? "patients" : "clients"}, any modifications will be visible to those {user.practice_type == "dietetics" ? "patients" : "clients"}. </strong>
                <br/>
                <br/>
                To prevent this, please create a duplicate of the plan and make edits there. Do you want to proceed with saving your changes?
                </p>,
                () => {
                    this.onSavePlan(false, true);
                },
                () => false,
                {acceptText: 'Yes, Save', rejectText: 'No, Cancel'},
            );
            return;
        }

        this.onSavePlan(false, true);
    }


    addPlanToFavorites = (plan) => {
        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: plan.uuid,
            resource_type: plan.type,
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }
    }

    onSavePlan = (duplicate = false, saveButton = false) => {
        // Save the plan to the database. If it's already curated, then we just save
        // it. If it's virtual, then we store it and reset our state to the new version.
        const { onSavePlan } = this.props;
        const { replaceMealPlan } = this.context;
        const { plan, user } = this.state;

        // status is a read-only field
        delete plan.status;

        // We also need to remove the local edit copy and the virtual copy.
        this.setState({saving: true});

        if (saveButton) {
            this.setState({saveButtonSaving: true});
        }

        if (!(plan.links && plan.links.self)) {
            // Create new document in database...
            // eslint-disable-next-line no-unused-vars
            const { uuid, links, created, type, status, merchant, ...rest } = plan;

            createNewDocument('plan', rest, 'plan/1').then(
                response => {
                    deleteVirtualPlan(plan.uuid, response.uuid);

                    store.remove('menu-edited-' + plan.uuid);
                    // Meal plan is no longer dirty, unset the dirty flag.
                    this.setState({
                        saving: false,
                        saveButtonSaving: false,
                        plan: response,
                        dirty: false,
                        dirtySinceLastSave: false
                    }, () => {
                        this.syncAssets();
                        this.initializePlan(); //initialize plan to set participants on meals
                    });

                    // If we have a replaceMealPlan context function, let's inform our ancestor of the change
                    replaceMealPlan && replaceMealPlan(plan, response);

                    updateCachedDocuments([response]);
                    this.addPlanToFavorites(response);
                    this.markSaved();
                    onSavePlan && onSavePlan(response);
                },
                error => this.setState({saving: false, saveButtonSaving: false, saveError: error.message})
            );

            return;
        }

        if (this.shouldCopyPlan() || duplicate) {
            // eslint-disable-next-line no-unused-vars
            const { uuid, links, created, type, status, merchant, ...rest } = plan;

            rest.owner = user.uuid;

            if (duplicate && !rest.title.startsWith('Copy - ')) {
                rest.title = 'Copy - ' + plan.title;
            }

            if (this.shouldCopyPlan() && !duplicate && !rest.title.startsWith('My ')) {
                rest.title = 'My ' + plan.title;
            }

            createNewDocument('plan', rest, 'plan/1').then(
                response => {
                    // Meal plan is no longer dirty, unset the dirty flag.
                    this.setState({saving: false, saveButtonSaving: false, plan: response, dirty: false, dirtySinceLastSave: false}, this.syncAssets);

                    // If we have a replaceMealPlan context function, let's inform our ancestor of the change
                    replaceMealPlan && replaceMealPlan(plan, response);

                    updateCachedDocuments([response]);
                    this.addPlanToFavorites(response);
                    //Don't this.markSaved() because we don't need to see the save button on duplication
                    onSavePlan && onSavePlan(response);
                },
                error => this.setState({saving: false, saveButtonSaving: false, saveError: error.message})
            );

            return;
        }

        // Otherwise, save it to it's self-link.
        this.savePlan(plan).then(
            response => {
                // Courtesy update of plan document in cache
                updateCachedDocuments([response]);

                // We're clean now, so remove our locally edited copy.
                store.remove('menu-edited-' + response.uuid);
                this.setState({lastSaved: moment(), saveError: false, saving: false, saveButtonSaving: false, dirty: false, dirtySinceLastSave: false});
                this.resetLastSaved();
                this.addPlanToFavorites(response);
                this.markSaved();
                onSavePlan && onSavePlan(response);
            },
            error => {
                this.setState({lastSaved: null, saveError: error.message, saving: false, saveButtonSaving: false});
            }
        );
    }

    checkRecommendations = () => {
        const { boardContent} = this.state;
        const { confirm } = this.context;

        const recommendation = boardContent?.dietitian_recommendation ? boardContent : null;

        if (recommendation) {
             confirm(
                <p className="t3">This meal plan cannot be removed from favorites since it was recommended by {recommendation.author_name}. Please contact {recommendation.author_name} to request removal.</p>,
                () => false,
                () => false,
                {rejectText: null}
            );

            return true;
        }

        return false;
    }

    getPlanMealNutrients(plan, assets, profile) {
        const nutrients = {}, allDay = {};

        const { portion = 1 } = profile;

        plan.items.forEach(item => {
            const { offset, meal: mealType } = item;
            let contribs = getNutrientsForMeals([item], {...assets.recipes, ...assets.details, ...assets.foods}, portion);

            // Add to the all-day prescription.
            nutrients[offset] = nutrients[offset] || {};
            nutrients[offset]['all-day'] = nutrients[offset]['all-day'] || {};

            allDay[offset] = allDay[offset] || {};

            Object.keys(contribs).forEach(nutrNo => {
                if (nutrients[offset]['all-day'][nutrNo]) {
                    nutrients[offset]['all-day'][nutrNo] += contribs[nutrNo];
                } else {
                    nutrients[offset]['all-day'][nutrNo] = contribs[nutrNo];
                }

                if (allDay[offset][nutrNo]) {
                    allDay[offset][nutrNo] += contribs[nutrNo];
                } else {
                    allDay[offset][nutrNo] = contribs[nutrNo];
                }

                nutrients[offset][mealType] = nutrients[offset][mealType] || {};

                if (nutrients[offset][mealType][nutrNo]) {
                    nutrients[offset][mealType][nutrNo] += contribs[nutrNo];
                } else {
                    nutrients[offset][mealType][nutrNo] = contribs[nutrNo];
                }
            });
        });

        const averages = computeAverageNutrients(allDay);

        return { nutrients, averages };
    }

    getDetailedPrescriptionPlanMismatches(plan, nutrients, profile, user, assets) {
        const mismatches = {};

        if (!profile) {
            return mismatches
        }

        const { prescriptions = [] } = profile;
        const { inhibit_warnings_for_merchants = [] } = user?.features || {};

        // Build a list of mismatches.
        // 1. Day mismatches - where the aggregate for the day doesn't match the all-day prescription
        // 2. Per-meal - where each meals aggregate doesn't match the prescription.
        // Question - for #2 - does this consider only explicit prescriptions or does it also
        //                     consider implicit prescriptions?

        /*

        mismatches = {
            0: {// offset used as key
                'all-day': {
                    '203': {
                        'value': 15.96,
                        'min': 60,
                        'max': 90,
                    }
                },
                'Breakfast': []
            }

        };

        */

        const allDayRx = prescriptions.filter(p => p.meal_type === 'all-day')[0];

        // Make sure that we're not going to exceed the patients prescription for this meal
        const rxIndex = allDayRx ? {'all-day': allDayRx} : {};

        validMealTypes.forEach(mealType => {
            const rx = prescriptions.find(p => p.meal_type === mealType) ||
                   (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

            if (!rx) {
                return;
            }

            rxIndex[mealType] = rx;
        });

        const planMealTypes = this.getPlanMealTypes(plan);
        const planningAllFullMeals = planMealTypes.includes('Breakfast') &&
                                     planMealTypes.includes('Lunch') &&
                                     planMealTypes.includes('Dinner');

        Object.keys(nutrients).forEach(offset => {
            const daysMeals = plan.items.filter(item => item.offset == offset);
            const mealTypesThisDay = [];
            daysMeals.forEach(m => {
                if (!mealTypesThisDay.includes(m.meal)) {
                    mealTypesThisDay.push(m.meal);
                }
            })

            // We use the computed or explicit snack prescription by default
            let snackNeededNutrients = rxIndex.Snack;
            let plannedAllFullMeals = mealTypesThisDay.filter(mealType => ['Breakfast', 'Lunch', 'Dinner'].includes(mealType)).length === 3;

            if (mealTypesThisDay.includes('Snack') && allDayRx && plannedAllFullMeals) {
                const fullNutrients = addNutrientSet([
                    nutrients[offset].Breakfast,
                    nutrients[offset].Lunch,
                    nutrients[offset].Dinner,
                ].filter(v => v));

                // Compute the snack needed nutrients
                snackNeededNutrients = getNeededNutrients(fullNutrients, allDayRx.envelope);

                if (!rxIndex.Snack.implicit) {
                    snackNeededNutrients = limitEnvelopeByMaximums(snackNeededNutrients, rxIndex.Snack.envelope);
                }
            }

            Object.keys(rxIndex).forEach(mealType => {
                const mealTypeItems = mealType == 'all-day'
                    ? daysMeals
                    : daysMeals.filter(item => item.meal == mealType);

                const { content } = getPrimaryMeal(mealTypeItems, assets.recipes, assets.foods);

                let envelope = mealType === 'Snack' ? snackNeededNutrients : rxIndex[mealType].envelope;

                let shouldShowDevations = (envelope && nutrients[offset] && nutrients[offset][mealType]) &&
                    !inhibit_warnings_for_merchants.includes(content?.merchant?.uuid);

                let deviations = shouldShowDevations
                               ? compareNutrientsToEnvelope(nutrients[offset][mealType], envelope, ['FRU'])
                               : null;

                const LIMIT_TO_IGNORE = 0.01;

                deviations = deviations?.filter(({value, min, max}) =>
                    roundForHumans(min - value) >= LIMIT_TO_IGNORE || roundForHumans(value - max) >= LIMIT_TO_IGNORE);

                if (!(deviations && deviations.length > 0)) {
                    return;
                }

                mismatches[offset] = mismatches[offset] || {};
                mismatches[offset][mealType] = deviations;
            });

            if (!mismatches[offset]) {
                return;
            }

            // If the meal plan doesn't include the entire day, then don't
            // consider the all-day prescription.
            if (!planningAllFullMeals) {
                delete mismatches[offset]['all-day'];
            }

            // If our all day prescription is good, then our Snack prescription is also good.
            if (mismatches[offset].Snack && (!mismatches[offset]['all-day'] || !plannedAllFullMeals)) {
                delete mismatches[offset].Snack;
            }

            const fruitAllDayMismatch = (mismatches[offset]['all-day'] || []).filter(m => m.nutrNo === 'FRU')[0];

            // If the all-day fruit is satisfied, ignore any other per-meal fruit failures.
            if (!fruitAllDayMismatch) {
                // Strip fruit mismatches from all the other days.
                validMealTypes.forEach(mealType => {
                    if (!(mismatches[offset] && mismatches[offset][mealType])) {
                        return;
                    }

                    mismatches[offset][mealType] = mismatches[offset][mealType].filter(m => m.nutrNo !== 'FRU');
                });
            }
        });

        return { mismatches }
    }

    initializePlan = () => {
        return this.syncAssets().then(() => {
            const { plan } = this.state;

            const meals = this.initializeMeals(this.getMealsFromPlan(plan));
            plan.items = this.getPlanItemsFromMeals(meals);

            this.setState({plan});
            this.clearDeleted(false);
        });
    }


    syncAssets = () => {
        const { plan, user, profile } = this.state;

        if (!plan) {
            return Promise.reject('No plan loaded');
        }

        return getAssetsForMeals(plan.items).then(assets => {
            const { groceries } = analyzePlan(plan, assets, profile.units_mode);
            const { nutrients, averages } = this.getPlanMealNutrients(plan, assets, profile);

            const newState = {
                plan,
                groceries,
                nutrients,
                averages,
                ...assets,
                leftoversEnabled: profile.preferences.leftovers_enabled,
                maxLeftoverDays: profile.preferences.max_leftover_days,
                synced: true
            };

            if (profile.prescriptions) {
                const { mismatches } = this.getDetailedPrescriptionPlanMismatches(plan, nutrients, profile, user, assets);

                newState.mismatches = mismatches;
            }

            this.setState(newState);
        });
    }

    isPremiumContent = () => {
        const { plan, recipes } = this.state;

        // Determine if the plan or any recipe in it is protected
        let isPremiumContent = (plan.protection != 'public');

        plan.items.forEach(meal => {
            if (meal.deleted) {
                return;
            }

            const recipe = recipes[meal.recipe_uuid];

            if (!recipe) {
                isPremiumContent = true;
                return;
            }

            if (recipe.protection !== 'public') {
                isPremiumContent = true;
            }
        });

        return isPremiumContent;
    }

    generateMealId() {
        return uuidGen.v4().substring(0, 8);
    }

    isDuplicatePlan = () => {
        const { plan, dateStart } = this.state;

        if (!plan) {
            return false;
        }

        // Do we overlap at all with this same plan?
        const duplicate = PlanStore.getPlans().filter(p => {
            return (p.plan_uuid == plan.uuid || p.plan_uuid == plan.replaced_by) &&
                   dateStart.isSameOrAfter(p.date_start, 'day') &&
                   dateStart.isSameOrBefore(p.date_end, 'day');
        });

        return duplicate[0] || false;
    }

    wouldOverwriteScheduledMeals = (plan, dateStart) => {
        if (!dateStart) dateStart = moment();

        const dateEnd = moment(dateStart).add(plan.breakfasts || plan.lunches || plan.dinners || plan.snacks, 'day');

        // Any meals at all?
        const overwrittenMeals = MealStore.getMeals().filter(m => dateStart.isSameOrBefore(m.date, 'day') &&
                                                                  dateEnd.isSameOrAfter(m.date, 'day'));

        let wouldOverwriteMeals = overwrittenMeals.length > 0,
            wouldOverwriteGroceries = false,
            wouldOverwritePurchasedGroceries = false;

        const groceries = GroceryStore.getGroceries();
        for(let i in overwrittenMeals) {
            const primary = overwrittenMeals[i];

            const isInGroceries = isMealInGroceries(primary, groceries),
                  areGroceriesPurchased = isInGroceries && !isMealInGroceries(primary, groceries, true);

            if (isInGroceries) {
                wouldOverwriteGroceries = true;
            }

            if (areGroceriesPurchased) {
                wouldOverwritePurchasedGroceries = true;
            }
        }

        return { wouldOverwriteMeals, wouldOverwriteGroceries, wouldOverwritePurchasedGroceries };
    }

    realAddPlanToCalendar = (clearGroceries) => {
        const { router } = this.context;
        const { plan, dateStart, user, profile } = this.state;

        let planMarker = {
            uuid: uuidGen.v4(),
            plan_uuid: plan.uuid,
            date_start: dateStart.format('YYYY-MM-DD'),
            created: moment().format(),
        };

        let { meals, maxDate, mealTypes } = createMealsForPlan(plan, dateStart, [user].concat(profile.family), planMarker);
        const allMeals = MealStore.getMeals();
        const overwritten = allMeals.filter(m => moment(m.date).isBetween(dateStart, maxDate, 'day', '[]') &&
                                                 ['fresh', 'leftover', 'food'].includes(m.meal_type) &&
                                                 mealTypes.includes(m.meal) &&
                                                 !m.deleted);


        planMarker.date_end = maxDate.format('YYYY-MM-DD');

        if (clearGroceries) {
            GroceryActions.mealFeedRegen();
        }

        MealActions.deleteMeals(overwritten);
        PlanActions.upsertPlans([planMarker]);
        MealActions.upsertMeals(meals);

        Analytics.scheduleMealPlan({
            'Meal Plan UUID': plan.uuid,
            'Meal Plan Name': plan.title,
            'Plan Start Date': dateStart.toISOString()
        });
        router.push({pathname: `/meals`, query: {date: dateStart.format('YYYY-MM-DD')}});
    }

    onCompleteSignIn = () => {
        this.addPlanToCalendar();
    }

    onCompleteSignUp = () => {
        this.addPlanToCalendar();
    }

    createPlanIfNeeded = (plan, dirty) => {
        const { replaceMealPlan } = this.context;

        return new Promise((accept, reject) => {
            // If this is a virtual plan, or it's dirty, we save a copy of it to the db first.
            if ((plan.links && plan.links.self) && !dirty) {
                return accept(plan);
            }

            // Create new document in database...
            // eslint-disable-next-line no-unused-vars
            const { uuid, links, created, merchant, type, status, ...rest } = plan;

            createNewDocument('plan', rest, 'plan/1').then(
                response => {
                    deleteVirtualPlan(plan.uuid, response.uuid);

                    // If the meal plan is loaded again, we want to load the
                    plan.replaced_by = response.uuid;
                    this.updateEditedCopy(plan);
                    // store.remove('menu-edited-' + plan.uuid);

                    // If we have a replaceMealPlan context function, let's inform our ancestor of the change
                    replaceMealPlan && replaceMealPlan(plan, response);

                    updateCachedDocuments([response]);

                    // We intentionally use the old plan object here (it has more info in it than is stored in db)
                    // But we do need it update its UUID
                    plan.uuid = response.uuid;
                    plan.links = response.links;
                    accept(plan);

                    // Meal plan is no longer dirty, unset the dirty flag.
                    this.setState({saving: false, working: false, plan, dirty: false}, this.syncAssets);
                },
                error => {
                    this.setState({saving: false, working: false, saveError: error.message});
                    reject();
                }
            );
        });
    }

    /**
     * Should never be fired unless synced = true
     */
    addPlanToCalendar = () => {
        const { plan, dirty, user, synced, dateStart, working, dateSet } = this.state;
        const { confirm, router, isCordova } = this.context;
        const { location } = this.props;


        if (!synced || working) {
            return;
        }

        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        if (!user.capabilities.meal_planner && this.isPremiumContent()) {
            if (user.my_dietitian || isCordova) {
                confirm(
                    <div className="plan-editor-subscription-required">
                        <p>We're very sorry, but your account has expired.</p>
                        <p>Please contact your {user.practice_type === 'dietetics' ? 'dietitian' : 'personal trainer'} to proceed.</p>
                        <p>If you believe this to be in error, please email us at <a href="mailto:support@eatlove.is?subject=My account has expired">support@eatlove.is</a></p>
                    </div>,
                    () => false,
                    null,
                    {
                        titleText: <span>Account expired</span>,
                        acceptText: 'Close',
                        rejectText: '',
                    },
                );
            } else {
                confirm(
                    <SubscriptionRequired user={user} />,
                    () => {
                        router.push({pathname: '/membership'});
                    },
                    () => false,
                    {acceptText: 'Subscribe', rejectText: 'Cancel'},
                );
            }


            return;
        }

        if (!dateSet || !dateStart) {
            const { pathname, query, hash } = location;

            router.push({pathname, query: {...query, pickDate: 1}, hash});

            return;
        }

        const { wouldOverwriteMeals, wouldOverwriteGroceries, wouldOverwritePurchasedGroceries } = this.wouldOverwriteScheduledMeals(plan, dateStart);

        const goBabyGo = (clearGroceries = false) => {
            this.setState({working: true});

            this.createPlanIfNeeded(plan, dirty)
                .then(() => this.realAddPlanToCalendar(clearGroceries));
        }

        const cancelOut = () => {
            this.setState({dateStart: null, dateSet: false});
        };

        // If the groceries for the replaceMeals are already on hand,
        if (wouldOverwritePurchasedGroceries) {
            confirm(
                <div>
                    <p>Your groceries for meals on those dates are already on hand.</p>
                    <p>Replace the meals anyway? This will also clear your current grocery list.</p>
                </div>,
                () => goBabyGo(true),
                () => cancelOut(),
            );
            return;
        }

        if (wouldOverwriteGroceries) {
            confirm(
                <div>
                    <p>Scheduling this meal plan for this date will overwrite meals currently in your grocery list.</p>
                    <p>Replace the meals anyway? This will also clear your current grocery list.</p>
                </div>,
                () => goBabyGo(true),
                () => cancelOut(),
            );
            return;
        }

        if (wouldOverwriteMeals) {
            confirm(
                <div>
                    <p>You already have meals scheduled for these days, should I replace them?</p>
                    <p>This will also clear your current grocery list.</p>
                </div>,
                () => goBabyGo(true),
                () => cancelOut(),
                {}
            );
            return;
        }

        goBabyGo(false);
    }

    getDefaultAddSwapSettings = (participants, offset, mealType, replaceMeals = [], options = {}) => {
        const { profile, plan, recipes, leftoversEnabled, maxLeftoverDays } = this.state;
        const { editableParticipants } = this.props;

        const modalSettings = {
            ...options,
            defaultTypes: ['recipe', 'combo', 'food'],
            allowedTypes: ['recipe', 'combo', 'food', 'collection'],
            defaultTags: [],
            defaultAvoids: [],
            defaultExcludes: ['Condiment/Sauce', 'Baby Food', 'Purée', 'Foundational', 'Thanksgiving', 'Christmas', 'Supplement'],
            extraFilters: {},
            ideals: {},
            editableParticipants,
        };

        if (leftoversEnabled) {
            let servingsNeeded = participants.reduce((total, member) => total + member.portion, 0);

            modalSettings.extraFilters.sizes = Math.ceil(servingsNeeded);
            modalSettings.extraFilters.servings = {lte: Math.ceil(servingsNeeded * (maxLeftoverDays + 1))}
        }

        // Index the meals we're about to replace so they're easier to exclude
        const replaceIds = replaceMeals.map(meal => meal.id);

        // Get all of the meals on the same day.
        const meals = plan.items.filter(item => !item.deleted &&
                                                item.offset == offset &&
                                                !replaceIds.includes(item.id));

        // Get a list of recipe & foods we're about to replace so we can exclude them
        const excludeUuids = replaceMeals.map(meal => meal.recipe_uuid);

        const addSwapParams = {
            plan,
            mealType,
            replaceMeals,
            meals,
            contents: recipes,
            profile,
            modalSettings,
            excludeUuids
        };

        addDefaultAddSwapTags(addSwapParams);
        addDefaultAddSwapFilters(addSwapParams);

        return modalSettings;
    }

    getVanguardItem(plan, ignoreMeals) {
        const mealNames = ['Dinner', 'Lunch', 'Breakfast', 'Snack'];
        const happySortCompare = (a, b) => {
            // #1 Sort by meal type
            let ai = mealNames.indexOf(a.meal),
                bi = mealNames.indexOf(b.meal);

            if (ai != -1 && bi == -1) return -1;
            if (ai == -1 && bi != -1) return 1;

            if (ai > bi) return 1;
            if (ai < bi) return -1

            // #2 - by fresh / leftover
            if (a.type === 'fresh' && b.type !== 'fresh') return -1;
            if (a.type !== 'fresh' && b.type === 'fresh') return 1;

            // #3 Sort by mains vs. sides (if the recipes are available)
            if (a.side_dish && !b.side_dish) return 1;
            if (!a.side_dish && b.side_dish) return -1;

            return 0;
        }

        plan.items = plan.items || [];

        const ignoreIds = ignoreMeals.map(m => m.id);
        const items = (plan.items || []).filter(m => !ignoreIds.includes(m.id));

        // Get a unique list of offsets in this meal plan
        let offsets = items.map(pi => pi.offset);
        offsets = offsets.filter((o, i) => offsets.indexOf(o) === i);

        // Pick a random offset, get just the meals for that offset
        var offset = offsets[Math.floor(Math.random() * offsets.length)];
        var vanguard = items.filter(item => item.offset === offset).sort(happySortCompare)[0];

        if (!vanguard) {
            return;
        }

        return vanguard;
    }

    fixIndexAndTitle = (plan, oldMeals) => {
        const { recipes } = this.state;

        // If any of our removed meals are not "fresh" exclude them.
        oldMeals = oldMeals.filter(m => ['fresh'].includes(m.meal_type));

        const removed = getPrimaryMeal(oldMeals, recipes);

        // Pick a new title and image for the meal plan
        const newVanguard = getPrimaryMeal([this.getVanguardItem(plan, oldMeals)], recipes);

        if (removed.recipe && plan.title === removed.recipe.title + ' and more' && newVanguard.recipe) {
            plan.title = newVanguard.recipe.title + ' and more';
            plan.image = newVanguard.recipe.image;
        } else if (newVanguard?.recipe && plan.title == 'Empty meal plan') {
            plan.title = newVanguard.recipe.title + ' and more';
            plan.image = newVanguard.recipe.image;
        } else if (newVanguard?.food && plan.title === 'Empty meal plan') {
            plan.title = newVanguard.food.name + ' and more';
            plan.image = newVanguard.food.image;
        }

        return plan;
    }

    clearDeleted = (shouldUpdateEditedCopy = true) => {
        const { plan } = this.state;

        plan.items = plan.items.filter(item => !item.deleted);

        shouldUpdateEditedCopy && this.updateEditedCopy(plan);
        this.setState({plan}, this.syncAssets);
    }

    autosave = () => {
        const { plan } = this.state;

        this.updateEditedCopy(plan);
    }

    updateMealLeftovers = (meal, meals, content = null, leftoverOffsets = []) => {
        const { profile, leftoversEnabled } = this.state;

        const dirtyMeals = [meal];
        if (!leftoversEnabled || !content?.servings) {
            return {dirtyMeals, toRemove: [], leftovers: []};
        }

        const offset = parseInt(meal.offset) || 0;
        const totalServings = (meal.scaling || 1) * content.servings;
        const participants = getParticipantsForMeal(meal, profile);
        const neededPerMeal = participants.reduce((total, member) => total + (member.portion || 1), 0);
        const totalLeftovers = Math.floor(totalServings / neededPerMeal) - 1;

        // First, de-populate all the existing leftovers for this meal
        const oldLeftovers = meals.filter(item => item.parent == meal.id);

        const leftovers = [];

        let i = 1;
        while (i <= totalLeftovers && i <= 4) {
            let leftover;
            const leftoverOffset = offset + i;

            if (!leftoverOffsets.includes(leftoverOffset)) {
                i++
                continue;
            }

            if (oldLeftovers.length) {
                leftover = oldLeftovers.shift();
                leftover.meal = meal.meal;
            } else {
                leftover = {
                    ...meal,
                    id: this.generateMealId(),
                    meal_type: 'leftover',
                    offset: i + offset,
                    parent: meal.id,
                    leftover_servings: neededPerMeal,
                };
            }

            leftovers.push(leftover);
            dirtyMeals.push(leftover);

            i++;
        }

        return {dirtyMeals, toRemove: oldLeftovers, leftovers};
    }

    onRemoveMeal = (meal) => {
        this.onRemoveMeal([meal]);
    }

    onRemoveMeals = (meals) => {
        const { plan } = this.state;

        const removedIds = [];

        if (!(meals && meals.length > 0)) {
            return;
        }

        meals.forEach(meal => {
            if (!removedIds.includes(meal.id)) {
                removedIds.push(meal.id);
            }

            meal.deleted = true;
        });

        // immediately filter out leftovers. We'll rebuild them if the meal is undeleted.
        plan.items = plan.items.filter(item => !removedIds.includes(item.parent) || !item.parent);

        this.clearDeleted();
        this.updateEditedCopy(plan);

        this.setState({
            plan: this.fixIndexAndTitle(plan, meals),
            dirty: true,
            dirtySinceLastSave: true,
        }, this.syncAssets);

        Analytics.removeRecipe({
            'Meal Plan UUID': plan.uuid,
            'Meal Plan Name': plan.title,
            'Offset': meals[0].offset,
            'Meal Type': meals[0].mealType
        });
    }

    onModifyMeals = (mealsToUpsert, mealsToDelete = [], leftoverOffsets = null, additionalRecipes = []) => {
        let { plan, recipes, foods, maxOffset } = this.state;

        additionalRecipes.forEach((recipe) => {
            if (!recipes.hasOwnProperty(recipe.uuid)) {
                recipes[recipe.uuid] = recipe;
            }
        })

        mealsToDelete = mealsToDelete || [];
        let upsertLeftovers = [];


        // First pass, to process undeletes and leftovers
        mealsToUpsert.forEach(meal => {
            // Update leftovers
            if (leftoverOffsets) {
                const { content } = getPrimaryMeal([meal], recipes, foods);
                const { leftovers, toRemove } = this.updateMealLeftovers(
                    meal, plan.items, content, leftoverOffsets
                );

                upsertLeftovers = upsertLeftovers.concat(leftovers);
                mealsToDelete = mealsToDelete.concat(toRemove);
            } else if (!leftoverOffsets) {
                plan.items.forEach(item => {
                    if (meal.id === item.parent) {
                        // Update the details
                        item.recipe_uuid = meal.recipe_uuid;
                        item.details_uuid = meal.details_uuid;
                        item.food_uuid = meal.food_uuid;

                        upsertLeftovers.push(item);
                    }
                });
            }
        });


        if (mealsToDelete) {
            let replaceIds = mealsToDelete.map(item => item.id);
            replaceIds = replaceIds.filter((id, i) => i === replaceIds.indexOf(id));

            // Remove them (and their leftovers) immediately. No undo.
            plan.items = plan.items.filter(item => !(replaceIds.includes(item.id) ||
                                                     (item.parent && replaceIds.includes(item.parent))));
        }

        mealsToUpsert.concat(upsertLeftovers).forEach(meal => {
            // Ensure we're undeleting
            delete meal.deleted;

            // If the meal is already in the plan, update it, otherwise, add it.
            const orig = plan.items.find(item => meal.id == item.id);
            const i = plan.items.indexOf(orig);

            if (i === -1) {
                plan.items.push(meal);
            } else {
                plan.items[i] = meal;
            }
        });

        maxOffset = plan.items.reduce((max, item) => Math.max(max, item.offset), -Infinity);

        // If we remove the title or image card, change the title.
        plan = this.fixIndexAndTitle(plan, mealsToDelete);

        this.updateEditedCopy(plan);
        this.setState({plan, maxOffset, dirty: true, dirtySinceLastSave: true}, this.syncAssets);
    }

    onModifyMeal = (meal, replaceMeals, leftoverOffsets) => {
        this.onModifyMeals([meal], replaceMeals, leftoverOffsets);
    }

    /**
     * Proxies calls to getParticipantsForProfileByMealType because the main profile needs
     * to always be included when manually adding meals.
     *
     * @param  {[type]} profile  [description]
     * @param  {[type]} mealType [description]
     * @return {[type]}          [description]
     */
    getParticipantsForProfileByMealType = (profile, mealType) => {
        const participants = getParticipantsForProfileByMealType(profile, mealType);

        // Is our main profile participating? If not, then we add them because we're manually adding
        // this recipe and the main profile is supposed to be default included.

        if (profile.uuid && !isParticipating(participants, profile)) {
            participants.unshift({
                uuid: profile.uuid,
                name: profile.first_name || profile.name || profile.email,
                portion: profile.portion,
            });
        }

        return participants;
    }

    getMealsFromPlan(plan) {
        const collector = {};
        const meals = [];

        plan.items.forEach(item => {
            const { offset = 0, meal = 'Snack' } = item;

            // Ensure that fresh/leftover fields are populated
            item.meal_type = item.meal_type || 'fresh'

            // Give leftovers their own IDs.
            if (item.id === item.parent) {
                item.id = this.generateMealId();
            }

            collector[offset] = collector[offset] || {};
            collector[offset][meal] = collector[offset][meal] || [];

            collector[offset][meal].push(item);
        });

        Object.keys(collector).forEach(offset => {
            Object.keys(collector[offset]).forEach(mealType => {
                // Snacks never have side dishes.
                if (mealType === 'Snack') {
                    collector[offset][mealType].forEach(main => meals.push({offset, mealType, main, type: main.meal_type || 'fresh'}));

                    return;
                }

                const mains = collector[offset][mealType].filter(item => !item.side_dish);
                const sides = collector[offset][mealType].filter(item => item.side_dish);

                mains.forEach(main => {
                    const side = sides.shift();

                    // Is this a leftover? or not?
                    if (side) {
                        meals.push({offset, mealType, main, side, type: main.meal_type || 'fresh'});
                    } else {
                        meals.push({offset, mealType, main, type: main.meal_type || 'fresh'});
                    }
                });

                // If there's any sides remaining, we add them as stand alone sides
                sides.forEach(side => meals.push({offset, mealType, side, type: side.meal_type || 'fresh'}));
            });
        });

        return meals;
    }

    getPlanItemsFromMeals(meals) {
        const items = [];

        meals.forEach(meal => {
            const { deleted, main, side, offset, mealType } = meal;

            if (main) {
                main.deleted = deleted;
                main.offset = offset;
                main.meal = mealType;
                items.push(main);
            }

            if (side) {
                side.deleted = deleted;
                side.offset = offset;
                side.meal = mealType;
                items.push(side);
            }
        });

        return items;
    }

    initializeMeals = (meals) => {
        const { profile, recipes, foods } = this.state;

        const participants = {
            'Breakfast': getParticipantsForProfileByMealType(profile, 'Breakfast'),
            'Lunch': getParticipantsForProfileByMealType(profile, 'Lunch'),
            'Dinner': getParticipantsForProfileByMealType(profile, 'Dinner'),
            'Snack': getParticipantsForProfileByMealType(profile, 'Snack'),
        };

        const hasUndefinedParticipants = (meal) => {
            if (!meal.participants) {
                return true;
            }

            const currentParticipants = meal.participants.split(',');
            let participantUndefined = false;

            currentParticipants.forEach((uuid) => {
                if (!participants[meal.meal].find(participant => participant.uuid == uuid)) {
                    participantUndefined = true;
                    return;
                }
            });

            return participantUndefined;
        }

        // Ensure the following:
        // 1. All plan.items have an ID. We need it for various things around the customizer.
        // 2. All meals are set to the appropriate participants. We get the participants from the profile.

        meals.forEach(meal => {
            // Check if we have an ID or not.
            if (meal.main && !meal.main.id) {
                meal.main.id = this.generateMealId();
            }

            if (meal.side && !meal.side.id) {
                meal.side.id = this.generateMealId();
            }

            // If this is an unrecognized meal, we can't assign participants or scaling.
            if (!participants[meal.mealType]) {
                return;
            }

            const mainDish = recipes[meal.main?.recipe_uuid] ?? foods[meal.main?.food_uuid];
            const sideDish = recipes[meal.side?.recipe_uuid] ?? foods[meal.side?.food_uuid];

            // Does this meal need to be assigned participants, and thus, its scaling?
            if (meal.main &&
                hasUndefinedParticipants(meal.main) &&
                participants[meal.mealType] &&
                mainDish) {

                // aggregate the total number of servings needed for both the fresh and the leftovers
                meals.filter(m => m.main && m.main.meal_type === 'leftover' && m.main.parent === meal.main.id).forEach(leftover => {
                    if (!participants[leftover.mealType]) {
                        return;
                    }
                });

                if (mainDish.servings > 0) {
                    meal.main.participants = participants[meal.mealType].map(p => p.uuid || '').join(',');
                }

                if (meal.side && !meal.side.participants && participants[meal.mealType] && sideDish && sideDish.servings > 0) {
                    // The side dish needs to be scaled to the same number of days.
                    meal.side.participants = participants[meal.mealType].map(p => p.uuid || '').join(',');
                }
            }

            if (!meal.main &&
                meal.side &&
                hasUndefinedParticipants(meal.side) &&
                participants[meal.mealType] &&
                sideDish) {

                // aggregate the total number of servings needed for both the fresh and the leftovers
                meals.filter(m => m.main && m.main.meal_type === 'leftover' && m.main.parent === meal.side.id).forEach(leftover => {
                    if (!participants[leftover.mealType]) {
                        return;
                    }

                });

                if (sideDish.servings > 0) {
                    meal.side.participants = participants[meal.mealType].map(p => p.uuid || '').join(',');
                }
            }

            if (meal.meal_type === 'leftover' && participants[meal.mealType]) {
                const neededPerMeal = meal.main.participants[meal.mealType].reduce((total, member) => total + member.portion, 0);
                if (meal.main) {
                    // Update participants and leftover_servings on leftover items
                    meal.main.leftover_servings = neededPerMeal;
                }

                if (meal.side) {
                    meal.side.leftover_servings = neededPerMeal;
                }
            }

            if (mainDish?.servings > 0) {
                meal.main.scaling = this.getScaling(meal.main);
                //Set logged amount for cached plans
                meal.main.logged_amount = meal.main.logged_amount || profile.portion || 1;
                meal.main.logged_unit = meal.main.logged_unit || 'serving';
            }

            if (sideDish?.servings > 0) {
                meal.side.scaling = this.getScaling(meal.side);
                //Set logged amount for cached plans
                meal.side.logged_amount = meal.side.logged_amount || profile.portion || 1;
                meal.side.logged_unit = meal.side.logged_unit || 'serving';
            }

        });

        return meals;
    }

    isNotLoggedInThenShowLogin = () => {
        if (!this.state.user) {
            const { showLoginForm } = this.context;

            // Are we logged in? Perhaps we need to login.
            const loginFormOpts = {
                defaultMode: 'sign-in',
                signupLinkSwitchesModes: true,
                onCompleteSignUp: this.loadPlan,
                onCompleteSignIn: this.loadPlan,
                offModalCta: 'You are one more step away from finding delicious meals just for you.',
            };

            showLoginForm && showLoginForm(loginFormOpts);
            return true;
        }
    }

    startAddMeal = (offset, mealType, options = {}) => {
        const { plan, profile } = this.state;

        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const participants = this.getParticipantsForProfileByMealType(profile, mealType);
        const modalSettings = this.getDefaultAddSwapSettings(participants, offset, mealType, [], options);

        // Make a copy of our meal plan and remove any deleted items
        const copyOfPlan = JSON.parse(JSON.stringify(plan));
        copyOfPlan.items = (copyOfPlan.items || []).filter(item => !item.deleted);
        const addSwapContext = {
            mode: "add",
            offset,
            mealType,
            profile,
            participants,
            fullBrowserSearchPlaceholder: "What would you like to add?",
            ...options,
        };

        // Append the 'Add Recipe' query so the back button works.
        const { location } = this.props;
        const { router } = this.context;
        const { pathname, hash, query } = location;
        query.addRecipe = 1;
        router.push({pathname, hash, query});

        this.setState({
            modalSettings,
            addMealOffset: offset,
            addMealType: mealType,

            addSwapContext,
        });

        Analytics.startAddRecipe({
            'Meal Plan UUID': plan.uuid,
            'Meal Plan Name': plan.title,
            'Offset': offset,
            'Meal Type': mealType,
        });
    }

    startReplaceMeal = (replaceMeal, options = {}) => {
        const { plan, profile } = this.state;

        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const mealType = replaceMeal.meal,
              offset = replaceMeal.offset;
        const replaceMeals = options.replace_single
                           ? [replaceMeal]
                           : plan.items.filter(meal => meal.meal == mealType && meal.offset == offset);

        const participants = this.getParticipantsForProfileByMealType(profile, mealType);
        const modalSettings = this.getDefaultAddSwapSettings(participants, offset, mealType, replaceMeals, options);

        const addSwapContext = {
            mode: "replace",
            offset,
            plan,
            mealType,
            participants,
            profile,
            fullBrowserSearchPlaceholder: "What would you like to add?",
        };

        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        query.swapRecipe = 1;

        router.push({pathname, hash, query});

        this.setState({
            replaceMeals,
            addSwapContext,
            modalSettings,
            addMealOffset: offset,
            addMealType: mealType,
        });

        Analytics.startReplaceRecipe({
            'Meal Plan UUID': plan.uuid,
            'Offset': offset,
            'Meal Type': mealType,
            'Replaced Recipe UUID': replaceMeal.recipe_uuid,
            'Replaced Recipe Name': replaceMeal.recipe_title
        });
    }

    startRepeatMeal = (mealsToRepeat) => {
        this.setState({isRepeatMealOpen: true, mealsToRepeat});
    }

    getRescheduleOverlaps = (mealsToReschedule, offset, mealType) => {
        let overlaps = [], clears = [];
        const { profile } = this.state;
        const { plan, recipes, foods } = this.state;
        const { primary, content } = getPrimaryMeal(mealsToReschedule, recipes, foods);

        const idsToReschedule = mealsToReschedule.map(m => m.id);

        // Are there meals at the destination?
        const destMeals = plan.items.filter(item => {
            return (
                !item.deleted &&
                item.meal == mealType &&
                item.offset == offset &&
                !idsToReschedule.includes(item.id) &&
                !idsToReschedule.includes(item.parent)
            );
        });

        if (destMeals.length) {
            overlaps.push({
                mealType,
                offset,
                destMeals,
                sourceMeals: mealsToReschedule,
            });
        } else {
            clears.push(offset);
        }

        const isNotLeftover = mealsToReschedule.find(meal => meal.meal_type != "leftover");

        if (content?.servings && isNotLeftover) {
            const participants = getParticipantsForMeal(primary, profile);
            const loggedServings = getLoggedServingsOfRecipe(primary, content);
            const leftoverlaps = this.getLeftoverOffsetOverlaps(content, primary.scaling, participants, offset, mealType, idsToReschedule, false, loggedServings);

            leftoverlaps.overlaps?.forEach(leftoverOffset => {
                // Find the meals at the destination that would be overwritten.
                const destMeals = plan.items.filter(m => m.meal === mealType &&
                                                    leftoverOffset === parseInt(m.offset) &&
                                                    !idsToReschedule.includes(m.uuid) &&
                                                    !idsToReschedule.includes(m.parent_uuid));

                // Nothing actually will overlap here
                if (destMeals.length === 0) {
                    clears.push(leftoverOffset);
                    return;
                }

                const daysDiff = leftoverOffset - offset;
                const leftoverTrueOffset = parseInt(primary.offset) + daysDiff;

                // we're assuming that any other dishes have been scaled to the same settings as the primary.
                let sourceMeals = null;

                sourceMeals = plan.items.filter(m => m.meal_type === 'leftover' &&
                                                leftoverTrueOffset === parseInt(m.offset) &&
                                                idsToReschedule.includes(m.parent));


                if (sourceMeals.length === 0) {
                    // clears.push(leftoverOffset);
                    return;
                }

                overlaps.push({
                    offset: leftoverOffset,
                    mealType,
                    destMeals,
                    sourceMeals,
                });
            });

            leftoverlaps.clears?.forEach(clearOffset => clears.push(clearOffset));
        }

        return { mealsToReschedule, overlaps, clears }
    }

    completeRescheduleMeals = (meals, offset, mealType, clears) => {
        meals.forEach(meal => {
            meal.offset = offset;
            meal.meal = mealType;
        });

        this.onModifyMeals(meals, [], clears);
        this.closeModal();
    }

    rescheduleMeals = (meals, offset, mealType) => {
        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const { mealsToReschedule, overlaps, clears } = this.getRescheduleOverlaps(meals, offset, mealType);

        // If there are no overlaps, just reschedule the meal right now
        if (!overlaps.length) {
            return this.completeRescheduleMeals(meals, offset, mealType, clears);
        }

        // There are overlaps. We need to display the confirm overwrite modal. It will
        // perform all the additional meal operations based upon the users answers.
        const newState = {
            isConfirmOverwriteOpen: true,
            confirmOverlaps: overlaps,
            confirmClears: clears,
            confirmOffset: offset,
            confirmMealType: mealType,
        };

        newState.mealsToReschedule = mealsToReschedule;

        this.setState(newState);
    }

    getLeftoverOffsetOverlaps = (recipe, scaling, participants, offset, mealType, parentIds = [], calculateLeftovers = true, totalDaysSet, loggedServings = null) => {
        const { plan, profile } = this.state;
        let overlaps = [], clears = [];

        const needed = getServingsNeededWithLoggedServings(profile, participants, loggedServings);

        if (!scaling) {
            scaling = Math.ceil(needed / (recipe.servings ?? 1));
        }

        const totalServings = (recipe.servings ?? 1) * scaling;

        let totalLeftovers;

        //Total days set by edit particpants
        if (totalDaysSet) {
            totalLeftovers = totalDaysSet - 1;
        // calculate leftovers is default behavior
        } else if (calculateLeftovers) {
            totalLeftovers = Math.floor(totalServings / needed) - 1;
        // use existing leftovers when moving an existing meal
        } else {
            totalLeftovers = plan.items.filter((item) => parentIds.includes(item.parent)).length;
        }

        let i = 1; offset = parseInt(offset);
        while (i <= totalLeftovers && i <= 4) {
            const leftoverOffset = offset + i;
            const toOverwrite = plan.items.filter(item => item.meal === mealType &&
                                                          parseInt(item.offset) == leftoverOffset &&
                                                          !parentIds.includes(item.parent));

            if (toOverwrite.length > 0) {
                overlaps.push(leftoverOffset);
            } else {
                clears.push(leftoverOffset);
            }

            i++;
        }

        return { clears, overlaps };
    }

    onSelectLeftoverOffsets = (leftoverOffsets, mealType = null) => {
        let { plan, leftoverParams, replaceMeals, addMealType } = this.state;
        const { location } = this.props;
        addMealType = mealType || addMealType

        // wuut?
        if (!leftoverParams) {
            this.closeLeftoverOffsetsModal();
            return;
        }

        const { recipe, combo, participants, scaling, mainRecipe, sideRecipe, clears, meal } = leftoverParams;

        // Find a list of meals that match addMealType and leftoverOffsets
        plan.items.forEach(item => {
            const offset = parseInt(item.offset);

            if (!(leftoverOffsets && leftoverOffsets.includes(offset))) {
                return;
            }

            if (item.meal === addMealType) {
                replaceMeals = replaceMeals || [];
                replaceMeals.push(item);
            } else if (meal && meal.meal === item.meal) {
                replaceMeals = replaceMeals || [];
                replaceMeals.push(item);
            }
        });

        this.setState({replaceMeals}, () => {
            leftoverOffsets = (leftoverOffsets || []).concat(clears);

            if (recipe) {
                this.onSelectRecipe(recipe, participants, scaling, leftoverOffsets);
            } else if (combo) {
                this.onSelectCombo(combo, mainRecipe, sideRecipe, participants, scaling, leftoverOffsets);
            } else if (location && location.query.editMeal) {
                this.onChangeParticipants(participants, scaling, leftoverOffsets);
            }
        });
    }

    createMealFromRecipe(recipe, participants, scaling = null) {
        const { profile } = this.state;
        const needed = participants.reduce((total, member) => total + member.portion, 0);

        if (!scaling) {
            scaling = Math.ceil(needed / recipe.servings);
        }

        let side_dish = ['Breakfast Side Dish', 'Lunch Side Dish', 'Side Dish'].filter(t => (recipe.tags || []).includes(t)).length > 0;

        let meal = {
            id: this.generateMealId(),
            meal_type: 'fresh',
            logged_amount: profile.portion,
            logged_unit: "serving",
            logged_grams: Math.round(recipe.grams_per_serving * 1000) / 1000 *  profile.portion,
            logged_milliliters: Math.round(recipe.milliliters_per_serving * 1000) / 1000 *  profile.portion,
            recipe_uuid: recipe.uuid,
            details_uuid: recipe.details,
            recipe_title: recipe.title,
            recipe_image: recipe.image,
            scaling,
            participants: participants.map(p => p.uuid || '').join(','),
            side_dish,
        };

        return meal;
    }

    createMealFromFood(food, logged_unit, logged_amount, logged_grams, logged_milliliters) {
        const { profile } = this.state;

        let meal = {
            id: this.generateMealId(),
            meal_type: 'food',
            food_uuid: food.uuid,
            recipe_title: food.pretty_name || food.name,
            logged_unit: logged_unit || "serving",
            logged_amount: logged_amount || profile.portion,
            logged_grams,
            logged_milliliters
        };

        return meal;
    }

    areParticipantsDifferent(newParticipants, oldParticipants) {
        const uuids1 = newParticipants.map(obj => obj.uuid);
        const uuids2 = oldParticipants.map(obj => obj.uuid);

        return !uuids1.every(uuid => uuids2.includes(uuid)) || !uuids2.every(uuid => uuids1.includes(uuid));
    }

    onSelectRecipe = (recipe, participants, scaling = null, leftoverOffsets = null) => {
        const { plan, recipes, profile } = this.state;
        let { replaceMeals, addMealOffset, addMealType } = this.state;
        let totalDays;

        // cheat a weee bit
        recipes[recipe.uuid] = recipe;
        let mealsToDelete = [...(replaceMeals || [])];

        if (!leftoverOffsets) {
            const servings = recipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const yeild = (scaling || 1) * servings;
            const leftovers = Math.max(Math.floor(yeild / needed) - 1, 0);

            if (leftovers) {
               leftoverOffsets = generateOffsets(addMealOffset, leftovers);

                plan.items.forEach(item => {
                    if (!leftoverOffsets.includes(parseInt(item.offset)) || mealsToDelete.find(meal => meal.id == item.id)) {
                        return;
                    }

                    if (item.meal === addMealType) {
                        mealsToDelete = mealsToDelete || [];
                        mealsToDelete.push(item);
                    }
                });
            }

            if (!leftovers) {
                const existingMealBatchInfo = getBatchInfo(addMealOffset, addMealType, profile, plan);

                if (existingMealBatchInfo && !this.areParticipantsDifferent(participants, existingMealBatchInfo.participants)) {
                    participants = existingMealBatchInfo.participants;
                    scaling = existingMealBatchInfo.mealServingsNeeded / recipe.servings;
                    totalDays = existingMealBatchInfo.totalDays;
                }
            }
        } else {
            const servings = recipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const totalDays = leftoverOffsets.length + 1;
            const totalNeeded = needed * totalDays;
            scaling = Math.ceil(totalNeeded / servings);
        }

        const replaceMealIds = mealsToDelete?.map(item => item.id);

        if (!scaling) {
            // We have to figure out our scaling from our participants.
            const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
            scaling = Math.ceil(neededPerMeal / recipe.servings);
        }

        // Are there leftovers that this recipe would overwrite?
        const { clears, overlaps } = this.getLeftoverOffsetOverlaps(
            recipe, scaling, participants, addMealOffset, addMealType, replaceMealIds, true, totalDays
        );

        if (!leftoverOffsets && overlaps.length > 0) {
            this.setState({
                leftoverOverlaps: overlaps,
                leftoverParams: {recipe, participants, scaling, clears}
            });

            return;
        }

        let meal = this.createMealFromRecipe(recipe, participants, scaling);

        meal.offset = addMealOffset;
        meal.meal = addMealType;

        this.onModifyMeals([meal], mealsToDelete, leftoverOffsets || clears);
        this.closeAddSwapModal();

        Analytics.addRecipe({
            UUIDs: [recipe.uuid],
            Offset: addMealOffset,
            Meal: addMealType,
            'Plan UUID': plan.uuid
        });
    }

    onSelectCombo = (combo, mainRecipe, sideRecipe, participants, scaling = null, leftoverOffsets = null) => {
        const { recipes, plan, replaceMeals, addMealOffset, addMealType, profile } = this.state;
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        let totalDays;

        // cheat just a little bit more.
        recipes[mainRecipe.uuid] = mainRecipe;
        recipes[sideRecipe.uuid] = sideRecipe;

        let mealsToDelete = [...(replaceMeals || [])];

        if (!leftoverOffsets) {
            const servings = mainRecipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const yeild = (scaling || 1) * servings;
            const leftovers = Math.max(Math.floor(yeild / needed) - 1, 0);

            if (leftovers) {
               leftoverOffsets = generateOffsets(addMealOffset, leftovers);

                plan.items.forEach(item => {
                    if (!leftoverOffsets.includes(parseInt(item.offset)) || mealsToDelete.find(meal => meal.id == item.id)) {
                        return;
                    }

                    if (item.meal === addMealType) {
                        mealsToDelete = mealsToDelete || [];
                        mealsToDelete.push(item);
                    }
                });
            }

            if (!leftovers) {
                const existingMealBatchInfo = getBatchInfo(addMealOffset, addMealType, profile, plan);

                if (existingMealBatchInfo && !this.areParticipantsDifferent(participants, existingMealBatchInfo.participants)) {
                    participants = existingMealBatchInfo.participants;
                    scaling = existingMealBatchInfo.mealServingsNeeded / mainRecipe.servings;
                    totalDays = existingMealBatchInfo.totalDays;
                }
            }
        } else {
            const servings = mainRecipe.servings || 1;
            const needed = participants.reduce((total, member) => total + member.portion, 0);
            const totalDays = leftoverOffsets.length + 1;
            const totalNeeded = needed * totalDays;
            scaling = Math.ceil(totalNeeded / servings);
        }

        const replaceMealIds = mealsToDelete?.map(item => item.id);

        if (!scaling) {
            scaling = Math.ceil(neededPerMeal / mainRecipe.servings);
        }

        const totalMainDays = Math.floor(scaling * mainRecipe.servings / neededPerMeal);
        const totalSideServingsNeeded = totalMainDays * neededPerMeal;
        const sideScaling = Math.ceil(totalSideServingsNeeded / sideRecipe.servings)

        // Are there leftovers that this recipe would overwrite?
        const { clears, overlaps } = this.getLeftoverOffsetOverlaps(
            mainRecipe, scaling, participants, addMealOffset, addMealType, replaceMealIds, true, totalDays
        );

        if (!leftoverOffsets && overlaps.length > 0) {
            this.setState({
                leftoverOverlaps: overlaps,
                leftoverParams: {combo, mainRecipe, sideRecipe, participants, scaling, clears}
            });

            return;
        }

        // Now add two more meals to the recipe list - main dish + side dish.
        const main = {
            id: this.generateMealId(),
            meal_type: 'fresh',
            logged_amount: profile.portion,
            logged_unit: "serving",
            logged_grams: Math.round(mainRecipe.grams_per_serving * 1000) / 1000 *  profile.portion,
            logged_milliliters: Math.round(mainRecipe.milliliters_per_serving * 1000) / 1000 *  profile.portion,
            meal: addMealType,
            offset: addMealOffset,
            recipe_uuid: mainRecipe.uuid,
            details_uuid: mainRecipe.details,
            recipe_title: mainRecipe.title,
            recipe_image: mainRecipe.image,
            scaling: scaling,
            participants: participants.map(p => p.uuid || '').join(','),
        };

        const side = {
            id: this.generateMealId(),
            meal_type: 'fresh',
            logged_amount: profile.portion,
            logged_unit: "serving",
            logged_grams: Math.round(sideRecipe.grams_per_serving * 1000) / 1000 *  profile.portion,
            logged_milliliters: Math.round(sideRecipe.milliliters_per_serving * 1000) / 1000 *  profile.portion,
            meal: addMealType,
            offset: addMealOffset,
            recipe_uuid: sideRecipe.uuid,
            side_dish: true,
            details_uuid: sideRecipe.details,
            recipe_title: sideRecipe.title,
            recipe_image: sideRecipe.image,
            scaling: sideScaling,
            participants: participants.map(p => p.uuid || '').join(','),
        };

        this.onModifyMeals([main, side], mealsToDelete, leftoverOffsets || clears);
        this.closeAddSwapModal();

        Analytics.addRecipe({
            UUIDs: [mainRecipe.uuid, sideRecipe.uuid],
            Offset: addMealOffset,
            Meal: addMealType,
            'Plan UUID': plan.uuid,
        });
    }

    onSelectFood = (food, unit = null, amount = 1, grams = null, milliliters = null) => {

        if (!unit || (!food.grams_per_serving && !food.milliliters_per_serving) || (!grams && !milliliters)) {
            this.setState({logPortionsFor: [food]});
            return
        }
        
        const portions = {};
        portions[food.uuid] = this.createMealFromFood(
            food, unit, amount, grams, milliliters
        );

        this.onSelectPortions([food], portions);
    }

    onSelectProducts = (foods) => {
        let { replaceMeals, addMealOffset, addMealType } = this.state;
        const { plan } = this.state;
        const dirtyMeals = [];

        foods.forEach(food => {
            let meal = this.createMealFromFood(food);
            meal.offset = addMealOffset;
            meal.meal = addMealType;
            meal.logged_amount = 1;
            meal.logged_unit = 'serving';
            meal.logged_grams = food.grams_per_serving;
            meal.logged_milliliters = food.milliliters_per_serving;

            // Cheat to insert this into our state
            this.state.foods[food.uuid] = food;

            dirtyMeals.push(meal);
        })

        this.onModifyMeals(dirtyMeals, replaceMeals);

        this.closeAddSwapModal();

        Analytics.addRecipe({
            UUIDs: foods.map(p => p.uuid),
            Offset: addMealOffset,
            Meal: addMealType,
            'Plan UUID': plan.uuid,
        });
    }

    onSelectPortions = (contents, portions) => {
        const { profile, plan, replaceMeals, addMealOffset, addMealType } = this.state;
        const { isPro } = this.context;

        let dirtyMeals = [], uuids = [], errors = [];

        contents.forEach(content => {
            if (isSunbasketFood(content)) {
                errors.push('sunbasket_meal');
            }

            let meal = null;

            if (content.type === 'food') {
                meal = this.createMealFromFood(content);
            } else {
                return;
            }

            meal.meal = addMealType;
            meal.offset = addMealOffset;

            if (portions[content.uuid]) {
                const { logged_unit, logged_amount, logged_grams, logged_milliliters } = portions[content.uuid];

                meal.logged_unit = logged_unit;
                if (logged_amount) meal.logged_amount = logged_amount;
                if (logged_grams) meal.logged_grams = Math.round(logged_grams * 1000) / 1000;
                if (logged_milliliters) meal.logged_milliliters = Math.round(logged_milliliters * 1000) / 1000;
            }

            uuids.push(content.uuid);
            dirtyMeals.push(meal);
        });

        const finishUp = () => {
            this.onModifyMeals(dirtyMeals, replaceMeals);
            this.closeAddSwapModal();

            Analytics.addRecipe({
                UUIDs: uuids,
                Offset: addMealOffset,
                Meal: addMealType,
                'Plan UUID': plan.uuid,
            });
        };

        if (!errors.length) {
            return finishUp();
        }

        let renderedError;

        if (isPro) {
            renderedError = [
                'This exact meal may or may not be available in your',
                profile.practice_type === 'dietetics' ? 'patient\'s' : 'client\'s',
                'area. If enabled via',
                profile.practice_type === 'dietetics' ? 'Patient' : 'Client',
                'Preferences, Sunbasket meals will be automatically recommended to your',
                profile.practice_type === 'dietetics' ? 'patient' : 'client',
                'based on timing, availability, and their nutrition',
                profile.practice_type === 'dietetics' ? 'prescription.' : 'profile.',
            ].join(' ');
        } else {
            renderedError = [
                'This exact meal may or may not be available in your area. If enabled via',
                'Preferences, Sunbasket meals will be automatically recommended in your',
                'meal feed based on timing, availability, and your nutrition',
                profile.practice_type === 'dietetics' ? 'prescription.' : 'profile.',
            ].join(' ');
        }

        // ideally in the future we'd render this differently based on the type of error
        this.context.confirm(
            renderedError,
            () => finishUp(),
            () => false,
            {
                titleText: 'Please note, this is a sample Sunbasket meal.',
                acceptText: "Ok",
                rejectText: "Cancel",
            },
        );
    }

    showMealDetails = (meals) => {
        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        const { primary } = getPrimaryMeal(meals);

        if (!primary) {
            return;
        }

        query.offset = primary.offset;
        query.mealType = primary.meal;

        router.push({pathname, hash, query});
    }

    showGroceries = () => {
        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const { plan } = this.state;
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        query.showGroceries = 1;

        router.push({pathname, hash, query});

        this.setState({plan});
    }

    showNutrition = () => {
        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const { plan } = this.state;
        const { router } = this.context;
        const { patient, location } = this.props;
        const { pathname, hash, query } = location;
        const { isPro } = this.context;

        query.showNutrition = 1;

        router.push({pathname, hash, query});
        this.setState({plan});

        Analytics.viewMealPlanNutrition({
            'Meal Plan UUID': plan.uuid,
            'Meal Plan Name': plan.title,
            'Patient UUID': isPro && patient ? patient.uuid : null
        });
    }

    showProfile = () => {
        if (this.isNotLoggedInThenShowLogin()) {
            return;
        }

        const { plan } = this.state;
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;

        query.showProfile = 1;

        router.push({pathname, hash, query});
        this.setState({plan});
    }

    onChangeParticipants = (participants, scaling, leftoverOffsets = null) => {
        let { plan, recipes, foods, replaceMeals, leftoversEnabled, totalDaysSet, profile } = this.state;
        const { location } = this.props;
        const { editMeal } = location.query;

        if (!editMeal) {
            return;
        }

        const meal = this.findMeal(plan.items, editMeal);
        if (!meal) {
            return;
        }

        const parent = this.findParentMeal(meal, plan.items);
        const content = this.getContent(meal, recipes, foods);

        if (!content?.servings) {
            return;
        }

        const mealDishes = this.getMealDishes(plan.items, meal);
        const mealIds = mealDishes.map(item => item.id);

        if (leftoversEnabled) {
            leftoverOffsets = this.processLeftovers(meal, content, scaling, participants, mealIds, totalDaysSet, leftoverOffsets, location.query.editMeal);

            if (leftoverOffsets === null) {
                return;
            }
        }

        if (leftoverOffsets) {
            this.recalculateMealDishesScaling(mealDishes, participants, recipes, leftoverOffsets, profile, plan);
        }

        let dirtyMeals = this.prepareDirtyMeals(mealDishes, meal, parent, participants, recipes, foods, leftoverOffsets, scaling);

        this.onModifyMeals(dirtyMeals, replaceMeals, leftoverOffsets);
        this.closeEditMealBatches();
    };

    processLeftovers = (meal, content, scaling, participants, mealIds, totalDaysSet, leftoverOffsets, editMealId) => {
        const loggedServings = getLoggedServingsOfRecipe(meal, content);
        const { clears, overlaps } = this.getLeftoverOffsetOverlaps(
            content,
            typeof scaling === 'object' ? scaling[editMealId] : scaling,
            participants,
            meal.offset,
            meal.meal,
            mealIds,
            true,
            totalDaysSet,
            loggedServings
        );

        if (!leftoverOffsets && overlaps.length > 0) {
            this.setState({
                leftoverOverlaps: overlaps,
                leftoverParams: { participants, scaling, clears, meal }
            });
            return null;
        }

        return leftoverOffsets || clears;
    };

    findMeal = (items, editMeal) => {
        return items.find(m => m.id === editMeal);
    };

    findParentMeal = (meal, items) => {
        return meal.meal_type === 'leftover' ? items.find(item => item.id === meal.parent) : meal;
    };

    getContent = (meal, recipes, foods) => {
        return recipes[meal.recipe_uuid] || foods[meal.food_uuid];
    };

    getMealDishes = (items, meal) => {
        return items.filter(item => item.offset === meal.offset && item.meal === meal.meal);
    };

    handleLeftovers = (meal, content, scaling, participants, editMealId, mealIds, totalDaysSet) => {
        const loggedServings = this.getLoggedServingsOfRecipe(meal, content);
        const { clears, overlaps } = this.getLeftoverOffsetOverlaps(
            content, typeof scaling === 'object' ? scaling[editMealId] : scaling, participants, meal.offset, meal.meal, mealIds, true, totalDaysSet, loggedServings
        );

        return { clears, overlaps, loggedServings };
    };

    recalculateMealDishesScaling = (mealDishes, participants, recipes, leftoverOffsets, profile, plan) => {
        mealDishes.forEach((dish, index) => {
            if (recipes[dish.recipe_uuid]) {
                mealDishes[index].scaling = recalculateScaling(dish, participants, recipes[dish.recipe_uuid], leftoverOffsets.length + 1, profile, plan);
            }
        });

        return mealDishes;
    };

    prepareDirtyMeals = (mealDishes, meal, parent, participants, recipes, foods, leftoverOffsets, scaling) => {
        let dirtyMeals = [];

        mealDishes.forEach(dish => {
            const content = recipes[dish.recipe_uuid] || foods[dish.food_uuid];
            if (content.servings) {
                if (!leftoverOffsets) {
                    dish.scaling = typeof scaling === 'object' ? scaling[dish.id] : scaling;
                }
                dish.leftovers_removed = meal.scaling * content.servings - parent.logged_potion;
                dish.participants = participants.map(p => p.uuid || '').join(',');
                dirtyMeals.push(dish);
            }
        });

        return dirtyMeals;
    };

    canSendToPatient = () => {
        const { plan, user, canCreate } = this.state;

        if (!(user && user.practice)) {
            return false;
        }

        // If this is a virtual plan and we have no create plan permissions, they can't recommend it.
        if (!canCreate && !(plan && plan.links && plan.links.self)) {
            return false;
        }

        return true;
    }

    onCompleteSendToPatientLogin = (user) => {
        // Is this user already a practice member? Forward them back to the recommend dialog
        if (user.links && user.links.practice && user.dietitian) {
            // The plan is probably currently reloading, as are the inquiries,
            // so we need to wait until the inquiries are finished before re-sending to the patient.
            if (this.inquiryPromise) {
                this.inquiryPromise.then(this.startSendToPatient);
                return;
            }

            this.setState({user}, this.startSendToPatient);
            return;
        }

        // Save the meal plan to a custom spot so onboarding can come back to it later
        // Expire after two weeks
        store.set('return-to-plan', this.state.plan.uuid, new Date().getTime() + 1000 * 3600 * 24 * 14)

        // Otherwise, forward the user to the PRO onboarding second page
        const { router } = this.context;
        router.push('/new-account');
    }

    startSendToPatient = () => {
        const { user } = this.state;
        const { showLoginForm } = this.context;

        if (!user) {
            // Display login modal w/ PRO context
            const loginFormOpts = {
                defaultMode: 'sign-up',
                signupLinkSwitchesModes: true,
                hideIDPs: true,
                offModalCta: 'You’re one more step from sending this meal plan to your client!',
                signUpCTA: 'Recommend Meal Plan',
                onCompleteSignUp: this.onCompleteSendToPatientLogin,
                onCompleteSignIn: this.onCompleteSendToPatientLogin,
                signupFormClassNames: 'pro-register-form',
                signupFormTitleText: 'Create an account to save this meal plan and access our full meal planning features.',
                brochureTitleText: <span>EatLove’s Nutrition Intelligence&trade; helps your clients with healthy eating habits.</span>,
                brochurePointsText: [
                    'Personalized meal recommendations that keep clients on-track and engaged with their health and wellness goals',
                    'Supports 30 of the most common medical conditions and lifestyle goals',
                    'More than 6000 delicious recipes from food and nutrition experts',
                    'Automatic grocery lists with optional delivery where available',
                    'Position clients to feel confident in their food choices by leveraging EatLove as a nutrition education platform',
                    'Smart restaurant swaps to empower your clients on-the-go',
                ]
            };

            showLoginForm && showLoginForm(loginFormOpts);
            return;
        }

        if (user && !(user.links && user.links.practice)) {
            return this.onCompleteSendToPatientLogin(user);
        }

        if (user.agreement_version !== '10.2017') {
            this.setState({needBAA: true});
            return;
        }

        const { router } = this.context;
        const { location } = this.props;
        const { pathname, hash, query } = location;
        query.sendToPatient = 1;

        router.push({pathname, hash, query});
    }

    scrollToTop = () => {
        if (!this.scrollable) {
            return;
        }

        $(this.scrollable).animate({scrollTop: 0}, 250);
    }

    addMaxOffset = () => {
        if (this.state.maxOffset >= 6) {
            return;
        }

        this.setState({maxOffset: this.state.maxOffset + 1, dirty: true, dirtySinceLastSave: true}, this.scrollToTop);
    }

    clearAllMeals = () => {
        let { plan, meals } = this.state;

        meals = [];
        plan.items = [];
        plan.title = 'Empty meal plan';

        this.updateEditedCopy(plan);
        this.setState({meals, plan, dirty: true, dirtySinceLastSave: true}, () => {
            this.syncAssets();
            this.scrollToTop();
        });
    }

    renderLoading = () => {
        const { loadFailure } = this.state;

        return (
            <div>
                <div className="loading-webapp">
                    <h3>Loading EatLove</h3>
                    <h1>
                        <i className="icon-spinner" />
                    </h1>
                    {loadFailure ?
                        <div className="load-failure alert">{loadFailure}</div>
                    : null}

                    {!loadFailure ?
                        <h4>Crunching the numbers</h4>
                    : null}
                </div>
            </div>
        );
    }

    renderAddMealsModal = () => {
        const { location } = this.props;
        const { profile, modalSettings, addSwapContext } = this.state;

        const { swapRecipe, addRecipe } = location.query;

        if (!((swapRecipe || addRecipe) && addSwapContext)) {
            return null;
        }

        return (
            <AddSwapRecipe
                profile={profile}
                closeModal={this.closeAddSwapModal}
                onSelectRecipe={this.onSelectRecipe}
                onSelectCombo={this.onSelectCombo}
                onSelectFood={this.onSelectFood}
                useComputedParams={true}
                modalTitle={swapRecipe ? 'Swap Meal' : 'Add a Meal'}
                fullBrowserParams={{inhibitSearchOnMount: false}}
                startAddMeal={this.startAddMeal}
                {...modalSettings} />
        );
    }

    renderMealDetails = () => {
        const { plan, profile } = this.state;
        const { location, editableParticipants } = this.props;
        const { offset, mealType } = location.query;

        if (!offset || !mealType) {
            return;
        }

        // Find the list of meals for this meal slot
        const meals = plan.items.filter(item => item.offset == offset && item.meal == mealType && !item.deleted);

        if (meals.length == 0) {
            return;
        }

        return (
            <MealDetails meals={meals} profile={profile}
                mealType={mealType} offset={offset}
                editableParticipants={editableParticipants}
                closeModal={this.closeMealDetailsModal} />
        );
    }

    renderLogPortionsModal = () => {
        const { profile, logPortionsFor } = this.state;

        if (!logPortionsFor) {
            return null;
        }

        return <LogPortionsModal closeModal={this.closeLogPortionsModal}
            profile={profile} requirePrecise={false}
            modalTitle="How much?"
            ctaText="Add Food"
            contents={logPortionsFor}
            onSelectPortions={this.onSelectPortions} />
    }


    renderRecommendModal = () => {
        const { plan, dirty, profile } = this.state;
        const { patient, location = {} } = this.props;

        if (!location.query.sendToPatient) {
            return;
        }

        return <RecommendModal closeModal={this.closeModal}
                    patient={patient}
                    plan={plan} profile={profile}
                    dirty={dirty} />
    }

    renderGroceriesModal = () => {
        const { plan, groceries, profile } = this.state;
        const { location } = this.props;

        if (!location.query.showGroceries) {
            return null;
        }

        return <GroceriesModal plan={plan} groceries={groceries} closeModal={this.closeModal} profile={profile} />
    }

    renderNutritionModal = () => {
        const { plan, nutrients, averages, profile } = this.state;
        const { location } = this.props;

        if (!location.query.showNutrition) {
            return null;
        }

        return (
            <NutritionModal plan={plan}
                nutrients={nutrients}
                averages={averages}
                profile={profile}
                closeModal={this.closeModal} />
        );
    }

    onPickDate = (dateStart) => {
        this.setState({dateStart, dateSet: true}, () => {
            this.closeModal();
            this.addPlanToCalendar();
        });
    }

    onPlanTitleChange = (ev) => {
        const { plan } = this.state;

        plan.title = ev.target.value;

        this.setState({plan}, this.autosave);
    }

    renderPickDateModal = () => {
        const { dateStart } = this.state;
        const { location } = this.props;

        if (!location.query.pickDate) {
            return null;
        }

        return (
            <PickDateModal dateStart={dateStart}
                onPickDate={this.onPickDate}
                closeModal={this.closeModal} />
        );
    }

    onAcceptAgreement = () => {
        this.setState({needBAA: false}, this.startSendToPatient);
    }

    renderBAAModal = () => {
        const { needBAA } = this.state;

        if (!needBAA) {
            return null;
        }

        return (
            <AgreementModal closeModal={() => this.setState({needBAA: false})}
                onAccept={this.onAcceptAgreement} />
        );
    }

    renderProfileModal = () => {
        const { profile } = this.state;
        const { location } = this.props;

        if (!location.query.showProfile) {
            return null;
        }

        return (
            <ProfileModal profile={profile} closeModal={this.closeModal} />
        );
    }

    getScaling = (meal) => {
        const { plan, recipes, foods, profile } = this.state;

        let allMeals = getMealAndLeftovers(meal, plan);

        if (meal.recipe_uuid || meal.food_uuid) {
            const recipe = recipes[meal.recipe_uuid] || foods[meal.food_uuid];
            const participants = getParticipantsForMeal(meal, profile);
            const totalDays = allMeals.length;
            const loggedServings = getLoggedServingsOfRecipe(meal, recipe);
            const primaryUserMealServingsNeeded = allMeals.reduce((sum) => sum + (loggedServings || profile.portion), 0);
            const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion), 0);
            const mealServingsNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + primaryUserMealServingsNeeded;

            return Math.ceil(mealServingsNeeded / recipe.servings);
        }

        return meal.scaling || 1;
    }

    renderEditMealServingsModal = () => {
        const { location, editableParticipants } = this.props;
        const { plan, recipes, foods, profile } = this.state;
        const { editMeal } = location.query;
        let contents = {};

        if (!editMeal) {
            return null;
        }

        // Find the meal
        const meal = plan.items.find(m => m.id === editMeal);

        if (!meal) {
            return null;
        }

        const mealDishes = plan.items.filter(m => m?.meal === meal.meal && m?.offset === meal.offset && !m?.deleted && (recipes[m?.recipe_uuid]?.servings || foods[m?.food_uuid]?.servings));

        mealDishes.forEach((dish) => {

            if(recipes[dish.recipe_uuid]?.servings) {
                contents[dish.recipe_uuid] = recipes[dish.recipe_uuid]
            }

            if(foods[dish.food_uuid]?.servings) {
                contents[dish.food_uuid] = foods[dish.food_uuid]
            }
        });

        const participants = getParticipantsForMeal(meal, profile);

        // Let the user use the new participant editor screen which also does batch cooking
        return (
            <EditMealServingsModal closeModal={this.closeEditMealBatches}
                contents={contents}
                mealDishes={mealDishes}
                profile={profile}
                plan={plan}
                participants={participants}
                editableParticipants={editableParticipants}
                onChangeParticipants={this.onChangeParticipants}
                onChangeTotalDays={(totalDaysSet) => this.setState({totalDaysSet})}/>
        );
    }

    /**
     * This modal is for asking the user if they want to overwrite any future planned meals with leftovers
     * that would be created from the action they're about to take.
     *
     * @return {[type]} [description]
     */
    renderLeftoverDatesModal = () => {
        const { leftoverOverlaps, leftoverParams } = this.state;

        if (!leftoverOverlaps) {
            return null;
        }

        return <LeftoverOffsetsModal offsets={leftoverOverlaps}
            closeModal={this.closeLeftoverOffsetsModal}
            params={leftoverParams}
            onSelectLeftoverOffsets={this.onSelectLeftoverOffsets} />
    }

    addMealType = (mealType) => {
        const { mealTypes } = this.state;

        if (!mealTypes.includes(mealType)) {
            mealTypes.push(mealType);

            return this.setState({mealTypes: mealTypes.sort(this.sortMealTypes), dirty: true, dirtySinceLastSave: true}, this.scrollToTop);
        }
    }


    renderPlanTitle = () => {
        const { firstRun } = this.props;
        const { profile, plan, maxOffset, mealTypes } = this.state;

        const isDinnerOnly = mealTypes.includes('Dinner') &&
                            !mealTypes.includes('Breakfast') &&
                            !mealTypes.includes('Lunch');

        let conditions = (profile && profile.conditions || []).map(c => c.name);
        let specialNames = conditionNamesToSpecialName(conditions);

        if (firstRun) {
            return (
                <h2>Welcome to EatLove. Here is your first {isDinnerOnly ? `${maxOffset+1}-dinner plan` : 'meal plan'} highly customized for <em>{specialNames.join(', ')}</em> and your individual needs:</h2>
            );
        }

        return (
            <span>
                <input type="text"  className="plan-title" value={plan.title} onChange={this.onPlanTitleChange} placeholder="Enter title here" />
            </span>
        );

    }

    renderConfirmOverwriteModal = () => {
        const { isConfirmOverwriteOpen, mealsToReschedule, confirmOverlaps, confirmClears, confirmOffset, confirmMealType } = this.state;

        if (!isConfirmOverwriteOpen || !(mealsToReschedule)) {
            return null;
        }

        return <ConfirmOverwriteModal meals={mealsToReschedule}
                    onModifyMeals={this.onModifyMeals}
                    overlaps={confirmOverlaps}
                    clears={confirmClears}
                    offset={confirmOffset}
                    mealType={confirmMealType}
                    closeModal={this.closeConfirmOverwriteModal} />
    }

    renderCloneMealModal = () => {
        const { plan, mealTypes, maxOffset, isRepeatMealOpen, mealsToRepeat } = this.state;

        if (isRepeatMealOpen && mealsToRepeat) {

            return <MealRepeatModal plan={plan}
                    meals={mealsToRepeat}
                    maxOffset={maxOffset}
                    allMealTypes={mealTypes}
                    onModifyMeals={this.onModifyMeals}
                    closeModal={this.closeModal} />
        }
    }

    render = () => {
        const { isMobile, isPro } = this.context;
        const { patient, firstRun, showPdfPrint, showAddToPlanner, showConsumerFavoriteButton, location } = this.props;
        const { working, user, profile, plan, maxOffset, mealTypes, synced, loading, saving, saveButtonSaving, canPublish, dirty, dirtySinceLastSave, saveError, averages, saved } = this.state;
        const { inhibit_swap = false, hide_nutrition = false, rd_override = false } = profile.preferences || {};

        const showSaveButton = (dirtySinceLastSave || (saved && !dirtySinceLastSave)) && (plan?.links?.self || (!patient && !location?.query?.virtual));
        const showDuplicateButton = plan?.links?.self;

        let saveButtonText = saveButtonSaving ? "saving" : "save changes";
        saveButtonText = saved && !dirtySinceLastSave ? "changes saved" : saveButtonText
        const saveButtonDisabled = (saved && !dirtySinceLastSave) || saveButtonSaving;

        if (loading || !synced) {
            return this.renderLoading();
        }

        if (!plan) {
            return this.renderLoading();
        }

        return (

            <div className="meal-plan-customizer">
                <Helmet title={`${plan.title} | EatLove`} />
                <div className="customizer-scroll-container" ref={this.realizeScrollable}>
                    {!patient ? <DietitianBanner maxOffset={maxOffset} plan={plan} /> : null}

                    <header>
                        {this.renderPlanTitle()}
                    </header>

                    {saveError ? <p className="error-msg">{saveError}</p> : null}

                    <MobileGrid plan={plan} profile={profile}
                        maxOffset={maxOffset}
                        mealTypes={mealTypes}
                        getDefaultAddSwapSettings={this.getDefaultAddSwapSettings} />

                    <div className="secondary-controls">
                        {showDuplicateButton ?
                            <button className="sub-action-btn duplicate-plan-btn" onClick={() => this.onSavePlan(true)}>
                                duplicate meal plan
                            </button>
                        : null}
                        {dirtySinceLastSave ?
                            <button className="sub-action-btn undo-changes-btn" onClick={this.onRevertPlan}>
                                undo saved changes
                            </button>
                        : null}

                        {(!firstRun && maxOffset < 6 && (!inhibit_swap || rd_override)) ?
                            <button className="sub-action-btn add-a-day" onClick={this.addMaxOffset}>
                                add a day
                            </button>
                        : null}

                        {(plan && plan.items && plan.items.length && (!inhibit_swap || rd_override)) > 0 ?
                            <button className="sub-action-btn clear-all-meals" onClick={this.clearAllMeals}>
                                clear all meals
                            </button>
                        : null}

                        {showSaveButton && !isMobile ?
                            <button disabled={saveButtonDisabled} onClick={this.onClickSaveButton} className="el-medium-btn el-green-btn">
                                {saveButtonText}
                                {saveButtonSaving ?  <i className="icon-spinner" /> : null}
                            </button>
                        : null}

                        {(!inhibit_swap || rd_override) ?
                            <div>
                                {!mealTypes.includes('Breakfast') ?
                                    <button className="sub-action-btn add-a-meal-type" onClick={() => this.addMealType('Breakfast')}>include breakfasts</button>
                                : null}

                                {!mealTypes.includes('Lunch') ?
                                    <button className="sub-action-btn add-a-meal-type" onClick={() => this.addMealType('Lunch')}>include lunches</button>
                                : null}

                                {!mealTypes.includes('Dinner') ?
                                    <button className="sub-action-btn add-a-meal-type" onClick={() => this.addMealType('Dinner')}>include dinners</button>
                                : null}

                                {!mealTypes.includes('Snack') ?
                                    <button className="sub-action-btn add-a-meal-type" onClick={() => this.addMealType('Snack')}>include snacks</button>
                                : null}
                            </div>
                        : null}

                        {canPublish ?
                            <PublishingTools plan={plan}
                                saving={saving}
                                dirty={dirty}
                                onSavePlan={this.onSavePlan}
                                onShowProfile={this.showProfile} />
                        : null}

                        {user && (!hide_nutrition || rd_override) ?
                            <div className="meal-plan-insights">
                                <header>
                                    <h4>Daily Avg. Meal Plan Insights</h4>
                                </header>

                                <MenuStats plan={plan} averages={averages}
                                    user={user}
                                    profile={profile}
                                    showNutrition={this.showNutrition}
                                    showGroceries={this.showGroceries} />
                            </div>
                        : null}
                    </div>
                </div>

                {!isMobile ?
                    (<div className="primary-actions" data-pro={!!isPro}>


                        {patient ?
                            <span>
                                <FavoriteButton onFavorite={this.onSavePlan} plan={plan}></FavoriteButton>
                                <SharePopup loading={saving} onClick={this.onSavePlan} dropdownBtnClass={"el-text-btn"} buttonText={""} plan={plan} />
                                {showPdfPrint && plan.links && plan.links.self ?
                                    <PlanToPdfButton classes="plan-to-pdf-btn" plan={plan} button={<><span>PRINT OR SAVE PDF</span></>} patient={patient} />
                                : null}
                                <button className="send-to-patient recommend-to" onClick={this.startSendToPatient}>
                                    Recommend to {patient.first_name}
                                </button>
                            </span>
                        : null}

                        {!patient && isPro ?
                            <span>
                                <FavoriteButton onFavorite={this.onSavePlan} plan={plan}></FavoriteButton>
                                <SharePopup loading={saving} onClick={this.onSavePlan} dropdownBtnClass={"el-text-btn"} buttonText={""} plan={plan} />
                                {showPdfPrint && plan.links && plan.links.self ?
                                    <PlanToPdfButton classes="plan-to-pdf-btn" plan={plan} button={<><span>PRINT OR SAVE PDF</span></>} patient={patient} />
                                : null}
                                <button className="send-to-patient" onClick={this.startSendToPatient}>
                                    Recommend to ...
                                </button>
                            </span>

                        : null}

                        {!patient && !profile.altPatient && showConsumerFavoriteButton && !isPro ?
                            <span>
                                <FavoriteButton onBeforeUnfavorite={this.checkRecommendations} onFavorite={this.onSavePlan} plan={plan}></FavoriteButton>
                            </span>

                        : null}

                        {!patient && !profile.altPatient && showAddToPlanner && !isPro ?
                            <button className="send-to-patient" onClick={!working ? this.addPlanToCalendar : null}>
                                {working
                                    ? <span>Doing the math <i className="icon-spinner" /></span>
                                    : 'Schedule This Plan'
                                }
                            </button>
                        : null}


                    </div>)
                : null}


                {isMobile ?
                    (<div className="primary-actions">

                        {patient || profile.altPatient || isPro ?
                            <span>
                                <div className="footer-icons-row">
                                    <FavoriteButton onFavorite={this.onSavePlan} plan={plan}></FavoriteButton>
                                    <SharePopup positionClassName={"el-popup-bottom-center"} loading={saving} onClick={this.onSavePlan} dropdownBtnClass={"el-text-btn"} buttonText={""} plan={plan} />
                                    {showPdfPrint && plan.links && plan.links.self ?
                                        <PlanToPdfButton classes="el-raspberry-outline-btn el-medium-btn" plan={plan} button={<i className="icon-print" />} patient={patient} />
                                    : null}
                                </div>
                                <button className="el-medium-btn el-raspberry-btn" onClick={this.startSendToPatient}>
                                    Recommend
                                </button>
                                {showSaveButton ?
                                    <button disabled={saveButtonDisabled} onClick={this.onClickSaveButton} className="el-medium-btn el-green-btn">
                                        {saveButtonText}
                                        {saveButtonSaving ?  <i className="icon-spinner" /> : null}
                                    </button>
                                : null}
                            </span>
                        : null}

                        {!patient && !profile.altPatient && showAddToPlanner && !isPro ?
                            <span>
                                <FavoriteButton onBeforeUnfavorite={this.checkRecommendations} onFavorite={this.onSavePlan} plan={plan}></FavoriteButton>
                                <button className="send-to-patient" onClick={!working ? this.addPlanToCalendar : null}>
                                    {working
                                        ? <span>Doing the math <i className="icon-spinner" /></span>
                                        : 'Schedule This Plan'
                                    }
                                </button>
                                {showSaveButton ?
                                    <button disabled={saveButtonDisabled} onClick={this.onClickSaveButton} className="el-medium-btn el-green-btn">
                                        {saveButtonText}
                                        {saving ?  <i className="icon-spinner" /> : null}
                                    </button>
                                : null}
                            </span>
                        : null}


                    </div>)
                : null}
                {working ?
                    <div className="working-veil">
                        <i className="icon-spinner" />
                        <h2>Crunching the numbers...</h2>
                        <i className="icon-calendar5" />
                    </div>
                : null}
                {this.renderMealDetails()}
                {this.renderAddMealsModal()}
                {this.renderGroceriesModal()}
                {this.renderNutritionModal()}
                {this.renderRecommendModal()}
                {this.renderPickDateModal()}
                {this.renderBAAModal()}
                {this.renderProfileModal()}
                {this.renderEditMealServingsModal()}
                {this.renderLeftoverDatesModal()}
                {this.renderLogPortionsModal()}
                {this.renderConfirmOverwriteModal()}
                {this.renderCloneMealModal()}
            </div>
        );
    }
}
