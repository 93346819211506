'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { pluralize, singularize } from 'inflected';
import { DragSource, DropTarget } from 'react-dnd';

import SmartFraction from '../../Widgets/SmartFraction.react';
import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import Popup from '../../Widgets/Popup.react';
import Combobox from '../../../pro/components/Widgets/Combobox.react';
import Alert from '../../Widgets/Alert/Alert.react';

import Analytics from '../../../utils/Analytics';
import { isSingular, roundForHumans } from '../../../utils/Math';
import NutritionInfoModal from '../../Nutrition/Modals/NutritionInfoModal.react';

import './EditIngredient.scss';
import { getContentForIngredient } from '../../../utils/Content';

const ingredientTarget = {
    hover(props, monitor) {
        var item = monitor.getItem();

        if (item.ingredient != props.ingredient) {
            props.moveIngredient(item.ingredient, props.ingredient);
        }
    },
};

const ingredientSource = {
    beginDrag(props) {
        return { ingredient: props.ingredient };
    },
    endDrag(props, monitor, component) {
        if (monitor.didDrop()) {
            // props.autosave();
            // console.log('@todo - we should save here');
        }
    },
    isDragging(props, monitor) {
        return props.ingredient === monitor.getItem().ingredient;
    },
};

@DropTarget('ingredient', ingredientTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))
@DragSource('ingredient', ingredientSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}))
export default class EditIngredient extends Component {
    static propTypes = {
        ingredient: PropTypes.object,
        onChangeIngredient: PropTypes.func,
        onRemoveIngredient: PropTypes.func,
    };

    static defaultProps = {};

    static contextTypes = {
        foods: PropTypes.object,
        subrecipes: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            ...this.getStateFromProps(props, context),

            isModalOpen: false,
            showNutritionModal: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true, ...this.getStateFromProps(this.props, this.context) });
    };

    UNSAFE_componentWillReceiveProps = (newProps, newContext) => {
        this.setState(this.getStateFromProps(newProps, newContext));
    };

    getStateFromProps = (props, context) => {
        const { ingredient } = props;

        const ingredientCopy = JSON.parse(JSON.stringify(ingredient));

        const verified =
            (ingredientCopy.food && ingredientCopy.food.verified) ||
            (ingredientCopy.recipe && ingredientCopy.recipe.verified);

        const newState = {
            verified,
            ingredient: ingredientCopy,
            grams: ingredient.grams,
            milliliters: ingredient.milliliters,
        };

        return newState;
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    onSave = () => {
        const { onChangeIngredient } = this.props;
        const { ingredient } = this.state;

        onChangeIngredient(ingredient);
        this.setState({ isModalOpen: false });
    };

    disableSaveBtn = (value) => {
        this.setState({ disableSaveBtn: value });
    };

    onChangeFoodUnit = (portion, unit_of_measure, amount, grams, milliliters, unit) => {
        let { ingredient } = this.state;
        const { extraAnalyticsProps } = this.props;

        const traits = {
            Ingredient: ingredient.ingredient,
            'Old Amount': ingredient.measurement?.amount,
            'Old Unit': ingredient.measurement?.unit_of_measure,
            'Old Grams': ingredient.grams,
            'Old Milliliters': ingredient.milliliters,
            ...extraAnalyticsProps,
        };

        ingredient.measurement = { amount, unit_of_measure };
        ingredient.grams = grams;
        ingredient.milliliters = milliliters;

        if (ingredient.food && unit) {
            ingredient.food.unit_amount = unit.amount;
            ingredient.food.unit_grams = unit.grams;
            ingredient.food.unit_milliliters = unit.milliliters;
            ingredient.food.unit_description = unit.description;

            traits['Amount'] = ingredient.measurement.amount;
            traits['Unit'] = ingredient.measurement.unit_of_measure;
            traits['Grams'] = ingredient.grams;
            traits['Milliliters'] = ingredient.milliliters;
        }

        if (ingredient.food?.uuid) {
            traits['Food UUID'] = ingredient.food.uuid;
        }

        this.setState({ ingredient });
        Analytics.editIngredientAmountInRecipe(traits);
    };

    onRemoveIngredient = () => {
        this.closeModal();

        const { onRemoveIngredient } = this.props;

        onRemoveIngredient();
    };

    onChangeIngredientText = (ev) => {
        const { ingredient } = this.state;

        ingredient.ingredient = ev.target.value;

        this.setState({ ingredient });
    };

    onChangePrepStepTerms = (terms) => {
        const { ingredient } = this.state;

        ingredient.prep_step = terms;

        this.setState({ ingredient });
    };

    onSelectPrepStepOption = (option) => {
        const { ingredient } = this.state;

        ingredient.prep_step = option;

        this.setState({ ingredient });

        this.refs.prep_step.setTypeAhead(option);
    };

    renderModal = () => {
        const { ingredient, isModalOpen, disableSaveBtn } = this.state;
        const { onSwapIngredient } = this.props;
        const { recipes = {}, foods = {} } = this.context;

        const content = getContentForIngredient(ingredient, { ...recipes, ...foods });

        const { measurement = {} } = ingredient;
        const { amount } = measurement;

        if (!isModalOpen) {
            return;
        }

        const food = ingredient.food && foods[ingredient.food.uuid];

        const prepStepOpts = (food?.advance_prep || []).map((prep) => ({
            label: prep.prep_step,
            value: prep.prep_step,
        }));

        return (
            <Modal
                isOpen={true}
                className="el-modal el-modal3 el-modal3-centered edit-ingredient-modal"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                contentLabel="Edit recipe ingredient"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal3-container">
                    <div className="el-modal-body-container el-modal3-body-container el-fonts">

                        {ingredient.food && !(ingredient.grams || ingredient.milliliters) ?
                            <Alert type="warning">We were unable to determine this ingredient's nutrition.<br /><em>Please confirm the Amount and Size below.</em></Alert>
                        : null}

                        <div className="el-labeled-input ingredient-text">
                            <label className="ingredient-text-label">Recipe Ingredient</label>
                            <input type="text" value={ingredient.ingredient} onChange={this.onChangeIngredientText} />
                        </div>

                        <div className="mapped-food">
                            {ingredient.food ? (<>
                                <label>Matched Grocery Item</label>
                                <p className="p3">
                                    {[food?.brand_name, food?.pretty_name || ingredient.food?.name].filter((v) => v).join(' ')}
                                    <button
                                        className="swap-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                        onClick={() => onSwapIngredient(ingredient)}
                                    >
                                        swap
                                    </button>
                                </p>
                            </>) : null}

                            {ingredient.recipe ? (<>
                                <label>Foundational Recipe</label>
                                <p className="p3">
                                    {ingredient.recipe.title}
                                    <button
                                        className="swap-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                        onClick={() => onSwapIngredient(ingredient)}
                                    >
                                        swap
                                    </button>
                                </p>
                            </>) : null}

                            {!ingredient.food && !ingredient.recipe ? (
                                <p className="p3">
                                    <em>None</em>
                                    <button
                                        className="map-btn el-small-btn el-link-no-underline-raspberry-btn"
                                        onClick={() => onSwapIngredient(ingredient)}
                                    >
                                        Link a grocery item for nutritional analysis
                                    </button>
                                </p>
                            ) : null}
                        </div>

                        <FoodUnitsSelector
                            data-error={ingredient.food && !(ingredient.grams || ingredient.milliliters)}
                            food={food || { sering_unit: 'g', grams_per_serving: ingredient.grams }}
                            meal={{
                                logged_portion: 1,
                                logged_unit:
                                    ingredient.food?.unit_description || ingredient.measurement?.unit_of_measure,
                                logged_amount: amount,
                            }}
                            onChangeAmount={this.onChangeFoodUnit}
                            disableSaveBtn={this.disableSaveBtn}
                        />

                        <div className="el-labeled-input prep-step">
                            <label className="prep-label">
                                Prep step <em>(optional)</em>
                            </label>
                            <Combobox
                                className="el-combobox-container"
                                options={prepStepOpts}
                                value={ingredient.prep_step}
                                defaultValue={ingredient.prep_step}
                                onSelectOption={this.onSelectPrepStepOption}
                                onChangeTerms={this.onChangePrepStepTerms}
                                ref="prep_step"
                            />
                        </div>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.onRemoveIngredient}>
                            remove
                        </button>
                        <button className="el-modal-ok-btn" disabled={disableSaveBtn} onClick={this.onSave}>
                            done
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    };

    showNutrition = () => {
        const { ingredient } = this.props;
        this.setState({ showNutritionModal: ingredient });
    };

    onCloseNutritionInfoModal = () => {
        this.setState({ showNutritionModal: null });
    };

    renderNutritionInfoModal = () => {
        const { showNutritionModal } = this.state;
        const { ingredient, profile } = this.props;
        const { recipes, foods } = this.context;

        if (!showNutritionModal) {
            return;
        }

        const contents = { ...recipes, ...foods };
        let content;
        let nutrients = [];

        if (ingredient.recipe) content = contents[ingredient.recipe.uuid];
        if (ingredient.food) content = contents[ingredient.food.uuid];

        if (content?.nutrients) {
            Object.keys(content?.nutrients?.values).forEach((nutrNo) => {
                if (content.serving_unit === 'g' && ingredient.grams) {
                    nutrients[nutrNo] =
                        (content.nutrients.values[nutrNo] / content.grams_per_serving) * ingredient.grams;
                } else if (content.serving_unit === 'ml' && ingredient.milliliters) {
                    nutrients[nutrNo] =
                        (content.nutrients.values[nutrNo] / content.milliliters_per_serving) * ingredient.milliliters;
                } else {
                    nutrients[nutrNo] = undefined;
                }
            });
        }

        const { amount, unit_of_measure } = ingredient.measurement || {};

        const subtitle = <>{[amount, unit_of_measure, ingredient.ingredient].filter((v) => v).join(' ')}</>;

        return (
            <NutritionInfoModal
                subtitle={subtitle}
                brandName={content?.brand_name}
                profile={profile}
                nutrients={nutrients}
                onClose={this.onCloseNutritionInfoModal}
            />
        );
    };

    render = () => {
        const { recipes, foods } = this.context;
        const { ingredient, profile, isDragging, connectDropTarget, connectDragSource, onSwapIngredient } = this.props;
        let { amount, unit_of_measure } = ingredient.measurement || {};

        const contents = { ...recipes, ...foods };
        let content = getContentForIngredient(ingredient, contents);

        const { preferences = {} } = profile;
        const { hide_nutrition } = preferences;

        if (unit_of_measure) {
            unit_of_measure = isSingular(amount) ? singularize(unit_of_measure) : pluralize(unit_of_measure);
        }

        const isWarning = !(ingredient.food || ingredient.recipe)
                       || !(ingredient.grams || ingredient.milliliters);

        const groceryName = [content?.brand_name, content?.pretty_name || ingredient?.food?.name || content?.name]
            .filter((v) => v)
            .join(' ');

        return connectDropTarget(
            <div className="ingredient" data-dragging={isDragging}>
                {isNaN(amount) && (ingredient.grams > 0 || ingredient.milliliters > 0) ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        {ingredient.grams > 0 ? <>{ingredient.grams}g</> : <>{ingredient.milliliters}ml</>}
                    </p>
                ) : null}

                {isNaN(amount) && !ingredient.grams && !ingredient.milliliters ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        &mdash;
                    </p>
                ) : null}

                {!isNaN(amount) ? (
                    <p className="ingredient-quantity" onClick={this.openModal}>
                        {profile.units_mode === 'metric' && !isNaN(amount) ? (
                            roundForHumans(amount)
                        ) : (
                            <SmartFraction acceptedDenominators={[2, 3, 4, 5, 6, 8]} value={amount} />
                        )}{' '}
                        {unit_of_measure}
                    </p>
                ) : null}

                {connectDragSource(
                    <div className="ingredient-item-name">
                        <p>
                            {ingredient.ingredient}
                            {ingredient.prep_step ? <span>, {ingredient.prep_step}</span> : null}
                        </p>
                        {groceryName ? (
                            <p className="mapped-food t3">
                                <em>grocery: {groceryName.toLowerCase()}</em>
                            </p>
                        ) : null}

                        {!groceryName && !ingredient.recipe ? (
                            <p className="mapped-food t3">
                                <em>grocery:</em>
                                <button
                                    className="select-grocery-item-btn el-medium-btn el-link-no-underline-raspberry-btn"
                                    onClick={() => onSwapIngredient(ingredient)}
                                >
                                    select a grocery item
                                </button>
                            </p>
                        ) : null}
                    </div>
                )}

                <div className="ingredient-controls">
                    {!isWarning && !hide_nutrition ? (
                        <button className="nutrition-info-btn" onClick={() => this.showNutrition()}>
                            <i className="icon-analyze" />
                        </button>
                    ) : null}

                    {isWarning && !hide_nutrition ? (
                        <button className="ingredient-alert-btn" onClick={this.openModal}>
                            <i className="feather feather-alert-triangle" />
                        </button>
                    ) : null}

                    <button className="edit-ingredient-btn" onClick={this.openModal}>
                        <i className="icon-pencil3" />
                    </button>
                </div>

                {this.renderModal()}
                {this.renderNutritionInfoModal()}
            </div>
        );
    };
}
